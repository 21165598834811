export function Footer() {
	const currentYear = new Date().getFullYear();
	return (
		<footer className="d-flex flex-wrap justify-content-between container align-items-center my-5 border-top">
			<div className="col-md-4 d-flex align-items-center">
				<a
					href="/"
					className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
				>
					Velaweb
				</a>
				<span className="text-muted">© {currentYear} Company, Inc</span>
			</div>

			<ul className="nav col-md-4 justify-content-end list-unstyled d-flex p-3">
				<li className="ms-3">
					<a className="text-muted" href="https://google.com">
						<i className="fa-brands fa-facebook fa-2x"></i>
					</a>
				</li>
				<li className="ms-3">
					<a className="text-muted" href="https://google.com">
						<i className="fa-brands fa-instagram fa-2x"></i>
					</a>
				</li>
				<li className="ms-3">
					<a className="text-muted" href="https://google.com">
						<i className="fa-brands fa-x-twitter fa-2x"></i>
					</a>
				</li>
			</ul>
		</footer>
	);
}
