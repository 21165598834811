import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserContext, CartProvider } from "./components/context";
import Cookies from 'js-cookie';
import "./config/i18next.config";

let checkCustomer = "false";
const cookie = Cookies.get('customer_id');
if (cookie > 0) {checkCustomer = "true";}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<>
		<Router>
			<UserContext.Provider value={checkCustomer}>
				<CartProvider><App /></CartProvider>
				</UserContext.Provider> 
		</Router>
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
