import { useState } from "react";
import axios from "../axiosConfig";
import * as constants from "../../constants";
import Swal from 'sweetalert2/dist/sweetalert2.all';
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";


export function CustomerForget() {
	// const baseurl = constants.SERVER_URL + 'customer/login/';
	const [cookie, setCookie] = useCookies(["ext"]);
	const baseurl = constants.SERVER_URL + 'customer/login/';
	const [formError, setFormError] = useState('');
	let checkCustomer = false;
	const [loginFormData, setLoginFormData] = useState({
		"email": ""
	});
	const { t } = useTranslation(["welcome"]);

	const inputHandler = (event) => {
		setLoginFormData({
			...loginFormData,
			[event.target.name]: event.target.value
		})
	};

	const getCsrfToken = async () => {
		try {
			const response = await axios.get(constants.SERVER_URL + 'get-csrf-token/');
			// console.log("CSRF Token response:", response.data.csrfToken);
			return response.data.csrfToken;
		} catch (error) {
			console.error("Error fetching CSRF token:", error);
			throw error;
		}
	};

	const submitHandler = async (event) => {
		// enviar datos en formato json
		event.preventDefault();
		const csrfToken = await getCsrfToken();
		// console.log("CSRF Token for request:", csrfToken);

		axios.patch(constants.SERVER_URL + 'customer/forget/', loginFormData, {
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrfToken
			}
		})
			.then(function (response) {

				if (!response.data.msg > 0) {
					setFormError(response.data.msg);
					Swal.fire({
						title: `${t("error")}`,
						text: `${t("errorEmail")}`,
						icon: 'error',
						toast: true,
						showConfirmButton: false,
					});
				} else {
					Swal.fire({
						title: `${t("senddEmail")}`,
						text: `${t("revEmailCont")}`,
						icon: 'success',
						toast: true,
						showConfirmButton: false,
					}).then(() => {
						setFormError(false);
						window.location.href = '/customer/login';
					})
				}
			})
			.catch(function (error) {
				console.log(error.response.data.msg);
				Swal.fire({
					title: `${t("error")}`,
					text: error.response.data.msg,
					icon: 'error',
					toast: true,
					showConfirmButton: false,
				})
			});
	};

	if (cookie.customer_id > 0) { checkCustomer = true; }
	if (checkCustomer) {
		window.location.href = "/customer/dashboard";
	}
	const buttonEnable = loginFormData.email.length > 0;

	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-8 col-12 offset-2">
					<div className="card">
						<h4 className="card-header">{t("pasRecv")}</h4>
						<div className="card-body">
							<p className="text-muted"><strong>{t("nota")}: </strong>{t("notaExpCorr")}</p>
							<div className="mb-3">
								<label htmlFor="email" className="form-label">
									{t("email")}
								</label>
								<input type="mail" className="form-control" value={loginFormData.email} name="email" id="email" onChange={inputHandler} />
							</div>
							<button disabled={!buttonEnable} type="button" onClick={submitHandler} className="btn btn-primary">
								{t("enviar")}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
