

export function SellerRegister(props) {
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 col-12 offset-2">
          <div className="card">
            <h4 className="card-header">Register</h4>
            <div className="card-body">
              <form>
                <div className="mb-3">
                  <label for="firstname" className="form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstname"
                  />
                </div>
                <div className="mb-3">
                  <label for="lastname" className="form-label">
                    Last name
                  </label>
                  <input type="text" className="form-control" id="lastname" />
                </div>
                <div className="mb-3">
                  <label for="username" className="form-label">
                    Username
                  </label>
                  <input type="text" className="form-control" id="username" />
                </div>
                <div className="mb-3">
                  <label for="email" className="form-label">
                    Email
                  </label>
                  <input type="email" className="form-control" id="email" />
                </div>
                <div className="mb-3">
                  <label for="pwd" className="form-label">
                    Password
                  </label>
                  <input type="password" className="form-control" id="pwd" />
                </div>
                <div className="mb-3">
                  <label for="rpwd" className="form-label">
                    Repeat Password
                  </label>
                  <input type="password" className="form-control" id="rpwd" />
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
