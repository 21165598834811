import React, { createContext, useReducer } from 'react';
export const UserContext = createContext({
	"login": false,
});


// Define initial state
const initialState = {
	cartData: [],
};

// Define reducer function to handle state updates
const cartReducer = (state, action) => {
	switch (action.type) {
		case 'SET_CART_DATA':
			return { ...state, cartData: action.payload };
		default:
			return state;
	}
};

// Create CartContext
export const CartContext = createContext();

// Create CartProvider component
export const CartProvider = ({ children }) => {
	const [state, dispatch] = useReducer(cartReducer, initialState);

	const setCartData = (cartData) => {
		dispatch({ type: 'SET_CART_DATA', payload: cartData });
	};

	return (
		<CartContext.Provider value={{ cartData: state.cartData, setCartData }}>
			{children}
		</CartContext.Provider>
	);
};


//create CurrencyContext
export const CurrencyContext = createContext();