import { Link, useParams } from "react-router-dom";
// import { SingleRelatedProduct } from "./single_related_product";
import { useState, useEffect, useContext } from "react";
import * as constants from "../constants";
import { UserContext } from "./context";
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useCookies } from "react-cookie";
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.all';
import { useTranslation } from "react-i18next";
import i18next from "i18next";


export function ProductDetail() {
	// const cartContext = useContext(CartContext);
	// const [relatedProd, setrelatedProd] = useState([]);
	// const [cartButtonClickStatus, setcartButtonClickStatus] = useState(true);
	// const [atry, setatry] = useState(0);
	// const { cartData, setCartData } = useContext(CartContext);
	const [cookie, setCookie] = useCookies(["ext"]);
	const customerId = cookie.customer_id; // Customer ID from localStorage
	const userContext = useContext(UserContext);
	const baseurl = constants.SERVER_URL;
	const [productData, setProducts] = useState([]);
	const [productImgs, setproductsImg] = useState([]);
	const [productTags, setproductsTag] = useState([]);
	const [productAttr, setproductAttr] = useState([]);
	const [ProductInWishList, setProductInWishList] = useState(false);
	const [pase, setPase] = useState(true);
	const { product_id } = useParams();

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [talla, setTalla] = useState(false);
	const tallaClose = () => setTalla(false);
	const tallaShow = () => setTalla(true);

	const [selectedValues, setSelectedValues] = useState([]);
	const [cantMax, setCantMax] = useState(10);
	const [cant, setCant] = useState(1);
	const { t } = useTranslation(["welcome"]);
	let lang = 'es';
	if (cookie.language) { lang = cookie.language; }
	const [productDataByLanguage, setProductDataByLanguage] = useState({});

	useEffect(() => {
		fetchData(baseurl + "products/" + product_id);
		// fetchRelatedData(baseurl + "related-products/" + product_id);
		// checkProductInCart(product_id);
		checkProductInWishList(product_id);
	}, []);


	const tagsLink = [];
	for (let i = 0; i < productTags.length; i++) {
		let tag = productTags[i].trim();
		tagsLink.push(<Link className="badge bg-secondary text-white me-1" key={i} to={`/product/${tag}`}>{tag}</Link>);
	}


	/**
	 * Sets the value of 'cant' state based on the event target value.
	 *
	 * @param {type} event - description of parameter
	 * @return {type} description of return value
	 */
	const handleCantChange = (event) => {
		setCant(event.target.value);
	}


	/**
	 * Check if a product is in the wishlist by making a POST request to the server.
	 *
	 * @param {type} product_id - The ID of the product to check in the wishlist
	 * @return {type} No return value
	 */
	function checkProductInWishList(product_id) {
		if (customerId > 0) {
			const formData = new FormData();
			formData.append("product", product_id);
			formData.append("customer", customerId);
			axios.post(constants.SERVER_URL + "check_wishlist/", formData, {
				headers: { 'Content-Type': 'application/json' },
			})
				.then((response) => {
					(response.data.msg === true) ? setProductInWishList(true) : setProductInWishList(false);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}


	/**
	 * A function that handles the change of attributes.
	 *
	 * @param {Event} e - The event triggering the attribute change
	 * @param {number} attribute_id - The ID of the attribute being changed
	 */
	const handleAttributeChange = (e, attribute_id) => {
		let updatedValues = [...selectedValues];

		// If selectedValues is null or empty, initialize it with the values of all dropdowns
		if (!updatedValues.length) {
			const selectElements = document.querySelectorAll('select[name^="attr-"]');
			updatedValues = Array.from(selectElements).map(selectElement => ({
				attribute_id: parseInt(selectElement.name.split('-')[1]),
				attribute_value_id: selectElement.value
			}));
		}

		// Update the value of the changed dropdown
		updatedValues.forEach((value, index) => {
			if (value.attribute_id === attribute_id) {
				updatedValues[index] = { ...updatedValues[index], attribute_value_id: e.target.value };
			}
		});

		// Update the value of all other dropdowns
		const selectElements = document.querySelectorAll('select[name^="attr-"]');
		selectElements.forEach(selectElement => {
			const attributeId = parseInt(selectElement.name.split('-')[1]);
			if (attributeId !== attribute_id) {
				const index = updatedValues.findIndex(value => value.attribute_id === attributeId);
				if (index !== -1) {
					updatedValues[index] = { ...updatedValues[index], attribute_value_id: selectElement.value };
				}
			}
		});

		let valor = '';
		updatedValues.forEach(element => {
			valor += element.attribute_value_id + ',';
		});

		valor = valor.slice(0, -1);
		fetch(baseurl + "products/attr/" + valor + "/" + productData.model) // carga los atributos que se pueden aplicar al producto
			.then((response) => response.json())
			.then((data) => {

				if (data.count !== 1) {
					Swal.fire({
						title: `${t("error")}`,
						text: `${t("errorAttr")}`,
						icon: 'error',
						toast: true,
						showConfirmButton: false,
						// position: 'center',
					});
				} else {
					if (data.results[0]) {
						fetchData(baseurl + "products/" + data.results[0].id);

						checkProductInWishList(data.results[0].id); //chequea si el producto esta en la lista de deseos
					}
				}
			})
			.catch((error) => console.error(error));

		setSelectedValues(updatedValues);
		// setcartButtonClickStatus(false);
	};

	// datos += opciones + '</select></br>';

	const buyNowButtonHandler = () => {
		cartAddButtonHandler(1);

	}


	/**
	 * A function that handles adding a product to the cart.
	 *
	 * @return {void} 
	 */
	const cartAddButtonHandler = (e) => {
		if (userContext !== null && userContext !== 'false') {
			// let previousCart = localStorage.getItem("cartData")
			// let cartJson = JSON.parse(previousCart)

			let precio = 0

			if (cookie.currency === 'eur') {
				precio = productData.price_eur;
			} else if (cookie.currency === 'usd') {
				precio = productData.price_usd;
			}
			const newData = {
				"product_id": productData.id,
				"cant": cant,
				"currency": cookie.currency,
				"sku": productData.sku,
				"price": precio,
				"customer_id": customerId
			};

			axios.post(constants.SERVER_URL + "orderitems/", newData, {
				headers: { 'Content-Type': 'application/json' },
			})
				.then((response) => {
					if (e === 1) {
						window.location.href = '/checkout';
					} else {
						window.location.reload(true);
					}
				})
				.catch((error) => {

					Swal.fire({
						title: `${t("error")}`,
						text: `${t("prodInCart")}`,
						icon: 'error',
						toast: true,
						showConfirmButton: false,
						// position: 'center',
					})
				})

			// setcartButtonClickStatus(true);
		} else {
			Swal.fire({
				title: `${t("unkUser")}`,
				text: `${t("mustLogin")}`,
				icon: 'error',
				toast: true,
				showConfirmButton: false,
				// position: 'center',
			})
		}

	}

	function AvisoExistencias() {
		WishlistAddButtonHandler(1);
		Swal.fire({
			title: `${t("prodAddWish")}`,
			text: `${t("corrExist")}`,
			icon: 'success',
			toast: true,
			position: 'center',
			showConfirmButton: false,
		})
	}

	function WishlistAddButtonHandler(aviso = 0) {
		if (userContext !== null && userContext !== 'false') {
			const formData = new FormData();

			formData.append("product", productData.id);
			formData.append("customer", customerId);
			formData.append("aviso", aviso);
			axios.post(constants.SERVER_URL + "wishlist/" + customerId + "/", formData, {
				headers: { 'Content-Type': 'application/json' },
			})
				.then((response) => {
					console.log(response.data);
					checkProductInWishList(productData.id);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			Swal.fire({
				title: `${t("unkUser")}`,
				text: `${t("mustLogin")}`,
				icon: 'error',
				toast: true,
				showConfirmButton: false,
				// position: 'center',
			})
		}

	}

	/**
	 * Fetches data from the specified URL and updates the products, 
	 * product images, product tags, and other state variables based on the retrieved data. 
	 * Also handles inventory and product attributes.
	 *
	 * @param {string} url - The URL from which to fetch the data
	 * @return {void} 
	 */
	async function fetchData(url) {
		await fetch(url)
			.then((response) => response.json())
			.then((data) => {
				setproductsImg(data.product_images);
				setproductsTag(data.tags_list);
				setPase(true);

				delete data.title;
				delete data.detail;
				delete data.tags;

				if (lang === 'es') {
					data.title = data.title_es;
					data.detail = data.detail_es;
					data.tags = data.tags_es;
				} else {
					data.title = data.title_en;
					data.detail = data.detail_en;
					data.tags = data.tags_en;
				}
				setProducts(data);

				if (data.product_images.length === 0) {
					data.product_images.push({ images: constants.SERVER_MEDIA + "product_images/default.jpg" });
					setPase(false);
				} else if (data.product_images.length === 1) {
					setPase(false);
				}

				if (data.inventario === true) {
					if (data.cant >= 10) {
						setCantMax(10);
					} else {
						setCantMax(data.cant);
					}
				} else {
					setCantMax(10);
				}

				fetch(baseurl + "products/def/" + data.model) // carga los atributos que se pueden aplicar al producto
					.then((response) => response.json())
					.then((data) => {
						if (data.length > 0) {

							const newData = data.map(item => {
								delete item.attribute_title;
								delete item.attribute_value;

								if (lang === 'es') {
									item.attribute_title = item.attribute_title_es;
									item.attribute_value = item.attribute_value_es;
								} else {
									item.attribute_title = item.attribute_title_en;
									item.attribute_value = item.attribute_value_en;
								}
								return item;
							});
							setproductAttr(newData);

						} else {
							setproductAttr([]);
						}
					})
					.catch((error) => console.error(error));
			}).catch(error => console.error(error));
	};


	return (

		<section className="container mt-4">
			<div className="row">
				<div className="col-4">
					<div id="carouselExampleControls" className="carousel slide carousel-dark carousel-fade" data-bs-ride="carousel">
						<div className="carousel-inner">
							{productImgs &&
								productImgs.map((images, index) => {
									if (index === 0) {
										return <div key={index} className="carousel-item active">
											<img onClick={handleShow} style={{ cursor: "zoom-in" }} src={images.images} className="img-thumbnail mb-5" alt={productData.title} />
										</div>
									} else {
										return <div key={index} className="carousel-item">
											<img onClick={handleShow} style={{ cursor: "zoom-in" }} src={images.images} className="img-thumbnail mb-5" alt={productData.title} />
										</div>
									}
								})}

						</div>
						{pase && (
							<>
								<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
									<span className="carousel-control-prev-icon" aria-hidden="true"></span>
									<span className="visually-hidden">{t("prev")}</span>
								</button>
								<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
									<span className="carousel-control-next-icon" aria-hidden="true"></span>
									<span className="visually-hidden">{t("next")}</span>
								</button>
							</>
						)}
					</div>
				</div>
				<div className="col-8">
					<h3 className="text-success">{productData.title}</h3>
					{productData.sku}
					{cookie.mrew === 1 && productData.rating &&
						<>
							<h5 className="card-title mt-4">{t("Ratg")}:
								{productData.rating.rating__avg &&
									productData.rating.rating__avg.toFixed(0) > 0 &&
									[...Array(productData.rating.rating__avg.toFixed(0) * 1)].map((_, i) => (
										<i key={i} className="fa fa-star text-warning"></i>
									))
								}

							</h5>
						</>
					}
					{productData.detail &&
						<div className="mt-4">
							<div dangerouslySetInnerHTML={{ __html: productData.detail && productData.detail.slice(0, productData.detail.indexOf('{break}')) }} />
						</div>}
					{productData.valor_eur !== productData.price_eur && productData.cant >= 1
						&& <h4 className="card-title text-danger mt-4">{t("prodDesc")}</h4>
					}
					{productData.cant >= 1 &&
						<div className="mt-4">
							{productData.valor_eur !== productData.price_eur &&
								cookie.currency === "eur" && <><h5 className="card-title">{t("price")}: &euro; {Number(productData.price_eur).toFixed(2)}</h5><p>{t("before")}: <s className="text-black-50">{Number(productData.valor_eur).toFixed(2)} </s></p></>
							}
							{productData.valor_usd !== productData.price_usd &&
								cookie.currency === "usd" && <><h5 className="card-title">{t("price")}: $ {Number(productData.price_usd).toFixed(2)}</h5><p>{t("before")}: <s className="text-black-40">{Number(productData.valor_usd).toFixed(2)}</s></p></>
							}
							{productData.valor_eur === productData.price_eur &&
								cookie.currency === "eur" && <h5 className="card-title"> {t("price")}: &euro; {Number(productData.price_eur).toFixed(2)}</h5>
							}
							{productData.valor_usd === productData.price_usd &&
								cookie.currency === "usd" && <h5 className="card-title"> {t("price")} : $ {Number(productData.price_usd).toFixed(2)}</h5>
							}
						</div>}
					<div className="mt-3">
						{productData.demo_url && (
							<Link title="Demo" target="_blank" to={productData.demo_url} className="btn btn-dark ms-1"><i className="fa-solid fa-desktop"></i> Demo</Link>
						)}

						{productData.cant >= 1 &&
							<button title={t("addCart")} onClick={cartAddButtonHandler} type="button" className="btn btn-primary ms-1"><i className="fa-solid fa-cart-plus"></i> {t("addCart")}</button>
						}
						{/* {cartButtonClickStatus && productData.cant > 0 &&
							<button title="Remove from Cart" onClick={cartRemoveButtonHandler} type="button" className="btn btn-warning ms-1"><i className="fa-solid fa-cart-arrow-down"></i> Remove from Cart</button>
						} */}
						{productData.cant >= 1 &&
							<button title={t("buyNow")} onClick={buyNowButtonHandler} className="btn btn-success ms-1"><i className="fa-solid fa-bag-shopping"></i> {t("buyNow")}</button>
						}
						{
							!ProductInWishList &&
							<button onClick={() => WishlistAddButtonHandler()} title={t("addWish")} className="btn btn-success ms-1"><i className="fa-solid fa-heart "></i> {t("addWish")}</button>
						}
						{
							ProductInWishList &&
							<button title={t("addeWish")} className="btn btn-success ms-1 disabled"><i className="fa-solid fa-check"></i> {t("addeWish")} </button>
						}

					</div>
					{tagsLink.length > 0 && (
						<>
							<div className="product-tags mt-4">
								<h5 className="card-title">{t("tags")}</h5>
								<p>
									{tagsLink}
								</p>
							</div>
						</>
					)}
					<div className="mt-4">
						{productData.cant >= 1 &&
							<>
								<div>
									<h5 className="card-title">{t("qtty")}:</h5>
								</div>
								<div>
									<select className="form-select" aria-label="Default select example" onChange={handleCantChange}>
										{Array.from({ length: cantMax }, (_, i) => (
											<option key={i} value={i + 1}>
												{i + 1}
											</option>
										))
										}
										{cantMax === undefined &&
											<div>{t("noDisp")}</div>
										}
									</select>
								</div>
							</>
						}
						{
							productData.cant === 0 &&
							<div>
								<h5 className="card-title">{t("noDispProd")}</h5>
								{cookie.ext === 1 && <button onClick={(e) => AvisoExistencias(e)} className="btn ms-1">{t("noDispProdAv")} <i className="fa-solid fa-envelope"></i></button>}
							</div>
						}
					</div>
					{productAttr.length > 0 && (
						<>
							<div className="product-attributes mt-4">
								<h5 className="card-title">{t("attr")}</h5>
								{Object.values(productAttr.reduce((acc, curr) => {
									if (!acc[curr.attribute_title]) {
										acc[curr.attribute_title] = [];
									}
									acc[curr.attribute_title].push(curr);
									return acc;
								}, {})).map((attributeGroup, index) => (
									<div key={index} className="mb-3">
										<label className="form-label">{attributeGroup[0].attribute_title}: </label>
										<select onChange={(e) => handleAttributeChange(e, attributeGroup[0].attribute_id)} className="form-select" name={`attr-${attributeGroup[0].attribute_id}`} aria-label={`Select ${attributeGroup[0].attribute_title}`}>
											<option value="">{t("choose")}</option>
											{attributeGroup.map((value, valueIndex) => (
												<option key={valueIndex} value={value.attribute_value_id} defaultValue={productData.attributes.some(attr => attr.attribute_value_id === value.attribute_value_id)} selected={productData.attributes.some(attr => attr.attribute_value_id === value.attribute_value_id)}>
													{value.attribute_value}
												</option>
											))}
										</select>
									</div>
								))}
							</div>
						</>
					)}
					{productData.talla && (
						<div className="mt-4">
							<button onClick={tallaShow} title={t("tallaShow")} className="btn ms-1"><i className="fa-solid fa-ruler"></i> {t("tallaShow")}</button>
						</div>
					)}

					<div className="mt-4">
						{productData.detail && <div dangerouslySetInnerHTML={{ __html: productData.detail.replace('*break*', '') }} />}
					</div>
				</div>
			</div>
			{/* Related Products */}
			{/* {relatedProd.length > 0 && (
				<>
					<h3 className="mt-5 mb-3 text-center">Related Products</h3>
					<div id="relatedProductSlider" className="carousel slide carousel-dark" data-bs-ride="carousel">
						<div className="carousel-indicators">
							{relatedProd.map((results, index) => {
								if (index === 0) {
									return <button key={index} type="button" data-bs-target="#relatedProductSlider" data-bs-slide-to="0" className="active" aria-current="true" aria-label={"Slide " + index}></button>
								} else {
									return <button key={index} type="button" data-bs-target="#relatedProductSlider" data-bs-slide-to="1" aria-label={"Slide " + index}></button>
								}
							})}

						</div>
						<div className="carousel-inner">
							{relatedProd.map((results, index) => {
								if (index === 0) {
									return <div key={index} className="carousel-item active">
										<SingleRelatedProduct product={results} />
									</div>
								} else {
									return <div key={index} className="carousel-item">
										<SingleRelatedProduct product={results} />
									</div>
								}
							})}

						</div>

					</div>
				</>
			)} */}
			{/* End Related Products */}
			{/* Reviews */}
			{cookie.mrew === 1 && productData.product_rating && (
				<>
					<h3 className="mt-5 mb-3 text-center">{t("prodRevw")}</h3>
					<div id="" className="">
						<div className="">

						</div>
						<div className="">
							{productData.product_rating.map((results, index) => {
								let usr = results.customer_username;
								let comt = results.reviews;
								let star = results.rating;
								return <div key={index} className="card mt-3">
									<div className="card-body">
										<h6 className="card-subtitle mb-2 text-body-secondary">{usr} {
											[...Array(star)].map((_, i) => (
												<i key={i} className="fa fa-star text-warning"></i>
											))}</h6>
										<p className="card-text">{comt}</p>
									</div>
								</div>

							})}

						</div>

					</div>
				</>
			)}
			{/* End Related Products */}
			{/* Modal para imagenes */}
			<Modal show={show} onHide={handleClose} size="lg">
				<Modal.Body>
					<div id="carouselModalControls" className="carousel slide carousel-dark carousel-fade" data-bs-ride="carousel">
						<div className="carousel-inner">
							{productImgs.map((images, index) => {
								if (index === 0) {
									return <div key={index} className="carousel-item active">
										<img src={images.images} alt={productData.title} width={"100%"} />
									</div>
								} else {
									return <div key={index} className="carousel-item">
										<img src={images.images} alt={productData.title} width={"100%"} />
									</div>
								}
							})}

						</div>
						{pase && (
							<>
								<button className="carousel-control-prev" type="button" data-bs-target="#carouselModalControls" data-bs-slide="prev">
									<span className="carousel-control-prev-icon" aria-hidden="true"></span>
									<span className="visually-hidden">{t("prev")}</span>
								</button>
								<button className="carousel-control-next" type="button" data-bs-target="#carouselModalControls" data-bs-slide="next">
									<span className="carousel-control-next-icon" aria-hidden="true"></span>
									<span className="visually-hidden">{t("next")}</span>
								</button>
							</>
						)}
					</div>
				</Modal.Body>
				{/* Fin Modal de imagenes */}
			</Modal>

			{/* Modal de Tallas */}
			<Modal show={talla} onHide={tallaClose} size="lg">
				<Modal.Body>
					{productData.talla && productData.talla.talla && <div dangerouslySetInnerHTML={{ __html: productData.talla.talla }} />}
					{productData.talla && productData.talla.codigo && <div dangerouslySetInnerHTML={{ __html: productData.talla.codigo }} />}
				</Modal.Body>
			</Modal>
			{/* Fin de Modal de Tallas */}
		</section>
	);
}
