import { Sidebar } from "./sidebar";
import { useState, useContext, useEffect } from 'react';
import { UserContext } from "../context";
import axios from "axios";
import * as constants from "../../constants";
import Swal from 'sweetalert2/dist/sweetalert2.all';
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";


export function Profile(props) {
	const [cookie, setCookie] = useCookies(["mrev"]);
	const [profileData, setProfileData] = useState({
		'firstname': '',
		'lastname': '',
		'username': '',
		'email': '',
		'mobile': '',
		'user_id': '',
		'image': '',
		'country': '',
		'refimage': '',
		'customer_id': '',
	});
	const userContext = useContext(UserContext);
	const customerId = cookie.customer_id; // Customer ID from localStorage
	const [formError, setFormError] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');
	const [UserId, setUserId] = useState('');
	const { t } = useTranslation(["welcome"]);
	const language = cookie.language;
	const [paises, setPaises] = useState([]);
	axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
	axios.defaults.xsrfCookieName = 'csrftoken';


	const fetchCSRFToken = async () => {
		try {
			const response = await axios.get(constants.SERVER_URL + 'dame_token/');
			return response.data.csrfToken;
		} catch (error) {
			console.error('Error al obtener el token CSRF:', error);
			return null;
		}
	};


	const submitHandler = async (event) => {
		// event.preventDefault(); // Prevent the default form submission
		const formUserData = new FormData();
		formUserData.append('first_name', profileData.firstname);
		formUserData.append('last_name', profileData.lastname);
		formUserData.append('username', profileData.username);
		formUserData.append('email', profileData.email);

		// const formData = new FormData();
		formUserData.append('user_id', UserId);
		let mobile = profileData.mobile.replace(/ /g, '');

		if (mobile * 1 > 0) {
			formUserData.append('mobile', mobile);
			formUserData.append('id_pais', profileData.country);

			const csrfToken = await fetchCSRFToken();

			axios.post(constants.SERVER_URL + "customer/" + customerId + "/check/", formUserData, {
				withCredentials: true,
				'X-CSRFTOKEN': csrfToken,
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Accept-Language': language,
				},
			}).then(function (response) {
				console.log(response)
				if (response.data.bool === false) {
					setFormError(true);
					setErrorMsg(response.data.msg);
				}
				else {
					Swal.fire({
						title: `${t("perfAct")}`,
						icon: 'success',
						toast: true,
						timer: 3000,
						// position: 'center',
						timerProgressBar: true
					});
				}
			}).then(data => {
				console.log(data);
			}).catch(function (error) {
				console.log(error.response.data.error);
				Swal.fire({
					title: `${t("perfActErr")}`,
					text: error.response.data.error,
					icon: 'error',
					toast: true
				});
			});

		} else {
			Swal.fire({
				title: `${t("perfActErr")}`,
				text: `${t("errorMobile")}`,
				icon: 'error',
				toast: true
			});
		}


	};

	useEffect(() => {
		fetch(constants.SERVER_URL + 'pais/')
			.then(response => response.json())
			.then(data => {
				delete data.nombre
				if (language === 'es') { data.nombre = data.nombre_es }
				else if (language === 'en') { data.nombre = data.nombre_en }

				setPaises(data)
			});

		fetchData();
	}, []);


	function insertSpaceAtPosition(str, position) {
		if (position >= 0 && position < str.length) {
			const before = str.slice(0, position);
			const after = str.slice(position);
			return before + " " + after;
		} else {
			return str; // No se puede insertar en una posición inválida
		}
	}


	const fetchData = async () => {
		try {
			// Primera llamada a axios
			let pais = '';
			const customerResponse = await axios.get(constants.SERVER_URL + "customers/" + customerId + "/");
			// pais = customerResponse.data.mobil.substr(1, customerResponse.data.mobil.indexOf(" ") - 1);
			const user_id = customerResponse.data.user_id_read;
			let { mobile, image, pais_id_read } = customerResponse.data;
			mobile = mobile.toString();
			switch (mobile.length) {
				case 7:
					mobile = insertSpaceAtPosition(mobile, 3);
					break;

				case 8:
					mobile = insertSpaceAtPosition(mobile, 1);
					mobile = insertSpaceAtPosition(mobile, 5);
					break;

				case 9:
					mobile = insertSpaceAtPosition(mobile, 3);
					mobile = insertSpaceAtPosition(mobile, 6);
					mobile = insertSpaceAtPosition(mobile, 9);
					break;

				case 10:
					mobile = insertSpaceAtPosition(mobile, 3);
					mobile = insertSpaceAtPosition(mobile, 7);
					break;

				default:
					break;
			}
			// if (mobile.substr(0, 1) === "+") {
			// 	pais = mobile.substr(1, mobile.indexOf(" ") - 1);
			// 	mobile = mobile.substr(mobile.indexOf(" ") + 1, mobile.length);
			// }


			setUserId(user_id);

			// Segunda llamada a axios
			const userResponse = await axios.get(constants.SERVER_URL + "users/" + user_id + "/");
			const { first_name, last_name, username, email } = userResponse.data;

			// Actualiza el estado con los datos obtenidos
			setProfileData({
				...profileData,
				mobile,
				image,
				user_id,
				customerId,
				firstname: first_name,
				lastname: last_name,
				username,
				email,
				refimage: image,
				country: pais_id_read
			});
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};


	const handleFileChange = (event) => {
		setProfileData({
			...profileData,
			[event.target.name]: event.target.files[0]
		})
	}

	const buttonEnable = profileData.username.length > 0 && profileData.email.length > 0 && profileData.firstname.length > 0 && profileData.lastname.length > 0 && profileData.mobile.length > 0 && profileData.country > 0;


	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-3 col-12 mb-2">
					<Sidebar />
				</div>
				<div className="col-md-8 col-12 mb-2">
					<h3 className="mb-3">{t("welcome", { nombre: profileData.username })}</h3>
					<div className="card">
						<h4 className="card-header">{t("updProf")}</h4>
						<div className="card-body">
							<p className="text-muted"><strong>{t("nota")}: </strong>{t("campReqMarc")}</p>
							{formError && <p className="text-danger mt-3">{errorMsg}</p>}
							{successMsg && <p className="text-success mt-3">{successMsg} </p>}
							<form>
								<div className="mb-3">
									<label htmlFor="firstname" className="form-label">
										{t("nombre")} *
									</label>
									<input type="text" className="form-control" id="firstname" defaultValue={profileData.firstname} onChange={(e) => setProfileData({ ...profileData, 'firstname': e.target.value })} />
								</div>
								<div className="mb-3">
									<label htmlFor="lastname" className="form-label">
										{t("apellidos")} *
									</label>
									<input type="text" className="form-control" id="lastname" defaultValue={profileData.lastname} onChange={(e) => setProfileData({ ...profileData, 'lastname': e.target.value })} />
								</div>
								<div className="mb-3">
									<label htmlFor="username" className="form-label">
										{t("username")}
									</label>
									<input type="text" readOnly className="form-control" id="username" defaultValue={profileData.username} onChange={(e) => setProfileData({ ...profileData, 'username': e.target.value })} />
								</div>
								<div className="mb-3">
									<label htmlFor="email" className="form-label">
										{t("email")} *
									</label>
									<input type="email" className="form-control" id="email" defaultValue={profileData.email} onChange={(e) => setProfileData({ ...profileData, 'email': e.target.value })} />
								</div>
								<div className="mb-3">
									<label htmlFor="mobile" className="form-label">
										{t("telef")} *
									</label>
									<div className="input-group">
										<select id="country" name="country" value={profileData.country} onChange={(e) => setProfileData({ ...profileData, 'country': e.target.value })}>											<option key="select-option" value="">{t("select")}</option>
											{paises &&
												paises.map((pais) => (
													<option key={pais.id} value={pais.id}>
														{pais.nombre} (+{pais.codtelf})
													</option>
												))}
										</select>
										<input type="text" className="form-control" id="mobile" defaultValue={profileData.mobile} onChange={(e) => setProfileData({ ...profileData, 'mobile': e.target.value })} />
									</div>
								</div>
								<div className="mb-3">
									<label htmlFor="image" className="form-label">
										{t("avatar")}
									</label>
									<input type="file" name="image" onChange={handleFileChange} className="form-control" id="image" />
									{profileData.image && <p><img src={profileData.image} width={80} className="img-thumbnail rounded0 mt-2" /></p>}
								</div>
								<button disabled={!buttonEnable} type="button" onClick={submitHandler} className="btn btn-primary">
									{t("enviar")}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
