import { Link } from "react-router-dom";
import { SellerSidebar } from "./sidebar";

export function SellerProducts() {
	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-3 col-12 mb-2">
					<SellerSidebar />
				</div>
				<div className="col-md-9 col-12 mb-2">
					<div className="mb-2">
						<Link to={"/seller/add_product"}>Add Products</Link>
					</div>
					<div className="table-responsive">
						<table className="table table-bordered">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Product</th>
									<th scope="col">Price</th>
									<th scope="col">Status</th>
									<th scope="col">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1</td>
									<td>
										<Link to={"/product/python-timer/123"}>
											<img
												src="https://www.vealaweb.com/static/images/articulos/updating_websites.webp"
												className="img-thumbnail"
												width={50}
												alt="..."
											/>
										</Link>
										<p>
											<Link to={"/product/python-timer/123"}>Product 1 </Link>
										</p>
									</td>
									<td>$100</td>
									<td>Published</td>
									<td>
										<Link to={"#"} className="btn btn-sm btn-info">
											View
										</Link>
										<Link to={"#"} className="btn btn-sm btn-primary ms-1">
											Edit
										</Link>
										<Link to={"#"} className="btn btn-sm btn-danger ms-1">
											Delete
										</Link>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
