import { Link } from "react-router-dom";
import { Sidebar } from "./sidebar";
import { useEffect, useState } from 'react';
import * as constants from "../../constants";
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.all';
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export function AddressList(props) {
	const [cookie, setCookie] = useCookies(["ext"]);
	const customerId = cookie.customer_id; // Customer ID from localStorage
	const [addressList, setAddressList] = useState([]);
	const { t } = useTranslation(["welcome"]);

	useEffect(() => {
		loadAddress();
	}, []);

	function loadAddress() {
		if (customerId > 0) {
			fetch(constants.SERVER_URL + "customer/" + customerId + "/address/")
				.then((response) => response.json())
				.then((data) => setAddressList(data))
		} else {
			Swal.fire({
				title: `${t("unkUser")}`,
				text: `${t("redirLogin")}`,
				icon: "error",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "OK"
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = '/customer/login'; // Redirect to the home page
				}
			});
		}
	}

	let direcciones = [];
	if (addressList.count === 0) {
		direcciones.push(
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h3>{t("noAddr")}</h3>
					</div>
				</div>
			</div>
		);
	} else {
		for (let i = 0; i < addressList.count; i++) {
			if (addressList.data[i].default) {
				direcciones.push(
					<div className="col-4 mb-4" key={i}>
						<div className="card bg-primary text-white">
							<div className="card-body">
								<h6>
									<i className="fa fa-check-circle mb-2"></i>
									<br />
									{addressList.data[i].address}
								</h6>
								<div className="form-group">
									<button className="btn btn-md float-end" onClick={() => deleteAddress(i)} title={t("delAddr")} ><i className="fa-regular fa-trash-can"></i></button><Link to={`/customer/mod_address/${addressList.data[i].id}`} className="btn btn-md float-end" title={t("editAddr")} ><i className="fa-regular fa-pen-to-square"></i></Link>
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				direcciones.push(
					<div className="col-4 mb-4 pe-auto" key={i}>
						<div className="card">
							<div className="card-body text-muted">
								<h6>
									<span className="badge bg-secondary mb-2" role="button" onClick={() => makeDefault(i)}>
										{t("makeAddr")}
									</span>
									<br />
									{addressList.data[i].address}
								</h6>
								<div className="form-group">
									<button className="btn btn-md float-end" onClick={() => deleteAddress(i)} title={t("delAddr")} ><i className="fa-regular fa-trash-can"></i></button><Link to={`/customer/mod_address/${addressList.data[i].id}`} className="btn btn-md float-end" title={t("editAddr")} ><i className="fa-regular fa-pen-to-square"></i></Link>
								</div>
							</div>
						</div>
					</div>
				);
			}
		}
	}

	const makeDefault = (i) => {
		const addressId = addressList.data[i].id;
		axios.patch(constants.SERVER_URL + "address/" + addressId + "/")
			.then(function (response) {
				console.log(response.data);
				loadAddress()
			});
	}

	const deleteAddress = (i) => {
		Swal.fire({
			title: `${t("segEliminar")}`,
			text: `${t("noRecuperas")}`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: `${t("siEliminar")}`,
			cancelButtonText: `${t("cancelar")}`
		}).then((result) => {
			if (result.isConfirmed) {
				const addressId = addressList.data[i].id;
				axios.delete(constants.SERVER_URL + "address/" + addressId + "/")
					.then(function (response) {
						console.log(response.data);
						loadAddress()
					});
			}
		})
	}

	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-3 col-12 mb-2">
					<Sidebar />
				</div>
				<div className="col-md-8 col-12 mb-2">
					<div className="mb-2">
						<Link to={"/customer/add_address"}>{t("addAddr")}</Link>
					</div>
					<div className="row">
						{direcciones}
					</div>
				</div>
			</div>
		</div>
	);
}
