import { Sidebar } from "./sidebar";
import { useState, useEffect } from 'react';
import * as constants from "../../constants";
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.all';
import { redirect, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export function ModAddress(props) {
	const [cookie, setCookie] = useCookies(["ext"]);
	const customerId = cookie.customer_id; // Customer ID from localStorage
	const [AddressForm, setAddressForm] = useState({
		'address': '',
		'customer': customerId,
	});
	const { address } = useParams();
	const { t } = useTranslation(["welcome"]);


	useEffect(() => {
		fetch(constants.SERVER_URL + "address/" + address)
			.then((response) => response.json())
			.then((data) => setAddressForm(data))
	}, []);


	const inputHandler = (event) => {
		setAddressForm({
			...AddressForm,
			[event.target.name]: event.target.value
		})
	}

	const submitHandler = (event) => {
		event.preventDefault(); // Prevent the default form submission
		// enviar datos en formato JSON
		axios.put(constants.SERVER_URL + "address/" + AddressForm.id + "/", AddressForm, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(function (response) {
				console.log(response.data);
				window.location.href = '/customer/addresses'; // Redirect to the home page
			})
			.catch(function (error) {

				Swal.fire({
					title: `${t("error")}`,
					text: error,
					icon: 'error',
					toast: true,
					// position: 'center',
				})
				console.log(error);
			});
	}

	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-3 col-12 mb-2">
					<Sidebar />
				</div>
				<div className="col-md-8 col-12 mb-2">
					<div className="card">
						<h4 className="card-header">{t("addAddr")}</h4>
						<div className="card-body">
							<div className="mb-3">
								<label htmlFor="address" className="form-label">
									{t("address")}
								</label>
								<textarea className="form-control" id="address" onChange={inputHandler} name="address" defaultValue={AddressForm.address} />
							</div>

							<button type="button" onClick={submitHandler} className="btn btn-primary">
								{t("enviar")}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
