import axios from 'axios';

const instance = axios.create({
	baseURL: 'your_base_url',  // Replace with your actual base URL
	timeout: 5000,  // Set timeout as needed
});

// Enable sending cookies with requests
instance.defaults.withCredentials = true;

export default instance;