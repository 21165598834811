import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import * as constants from "../constants";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";


export function Categories() {
	const [cookie, setCookie] = useCookies(["currency"]);
	const [categories, setCategories] = useState([]);
	const [totalResult, setTotalResults] = useState(0);
	const baseurl = constants.SERVER_URL + "categories/";
	const pageSize = cookie.tamPagina; // Number of items per page
	// const { t, i18n } = useTranslation(["welcome"]);
	const { t } = useTranslation(["welcome"]);

	useEffect(() => {
		fetchData(baseurl + "?offset=0&limit=" + pageSize);
	}, [baseurl, pageSize]);

	function fetchData(url) {
		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				setCategories(data.results);
				setTotalResults(data.count);
			});
	}

	function changeUrl(i) {
		fetchData(baseurl + "?offset=" + ((i - 1) * pageSize) + "&limit=" + pageSize);
	}

	if (totalResult > pageSize) {
		var links = [];
		var ultmPag = Math.ceil(totalResult / pageSize)
		for (let i = 1; i <= ultmPag; i++) {
			links.push(
				<li key={i} className="page-item">
					<Link className="page-link" onClick={() => changeUrl(i)} to={`/categories/?page=${i}`}>
						{i}
					</Link>
				</li>
			);
		}
	}

	return (
		<section className="container mt-4">
			<h3 className="mb-4">{t("allCateg")}</h3>
			<div className="row mb-2">
				{/* Category box */}
				{categories.map((category) => (
					<div key={category.id} className="col-12 col-md-3 mb-4">
						<div className="card">
							<img
								src="https://www.vealaweb.com/static/images/articulos/updating_websites.webp"
								className="card-img-top"
								alt="{category.title}"
							/>
							<div className="card-body">
								<h4 className="card-title">
									<Link to={`/category/${category.title}/${category.id}`}>{category.title}</Link>
								</h4>
								<div className="card-footer">Product download</div>
							</div>
						</div>
					</div>
				))}

				{/* Category box end */}
			</div>
			{/* Pagination */}
			<nav aria-label="Page navigation example">
				<ul className="pagination">
					{links}
				</ul>
			</nav>

			{/* Pagination End*/}
		</section>
	);
}
