import { SellerSidebar } from "./sidebar";

export function SellerPassword(props) {
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-3 col-12 mb-2">
          <SellerSidebar />
        </div>
        <div className="col-md-8 col-12 mb-2">
          <div className="card">
            <h4 className="card-header">Change Password</h4>
            <div className="card-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="pwd" className="form-label">
                    Password
                  </label>
                  <input type="password" className="form-control" id="pwd" />
                </div>
                <div className="mb-3">
                  <label htmlFor="rpwd" className="form-label">
                    Repeat Password
                  </label>
                  <input type="password" className="form-control" id="rpwd" />
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
