import { SellerSidebar } from "./sidebar";

export function SellerProfile(props) {
	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-3 col-12 mb-2">
					<SellerSidebar />
				</div>
				<div className="col-md-8 col-12 mb-2">
					<div className="card">
						<h4 className="card-header">Update Profile</h4>
						<div className="card-body">
							<form>
								<div className="mb-3">
									<label htmlFor="firstname" className="form-label">
										First name
									</label>
									<input type="text" className="form-control" id="firstname" />
								</div>
								<div className="mb-3">
									<label htmlFor="lastname" className="form-label">
										Last name
									</label>
									<input type="text" className="form-control" id="lastname" />
								</div>
								<div className="mb-3">
									<label htmlFor="username" className="form-label">
										Username
									</label>
									<input type="text" className="form-control" id="username" />
								</div>
								<div className="mb-3">
									<label htmlFor="email" className="form-label">
										Email
									</label>
									<input type="email" className="form-control" id="email" />
								</div>
								<div className="mb-3">
									<label htmlFor="image" className="form-label">
										Profile image
									</label>
									<input type="file" className="form-control" id="image" />
								</div>
								<button type="submit" className="btn btn-primary">
									Submit
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
