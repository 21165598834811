import { Link } from "react-router-dom";
import { useContext } from "react";
import { CartContext } from "./context";
import * as constants from "../constants";
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.all';
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";


export function Checkout() {
	const [cookie, setCookie] = useCookies(["currency"]);
	const { cartData, setCartData } = useContext(CartContext);
	const customerId = cookie.customer_id; // Customer ID from localStorage
	var totGen = 0;
	let precio = 0;
	let moneda = "";
	(cookie.currency === 'usd') ? moneda = '$' : moneda = '€';
	const { t } = useTranslation(["welcome"]);


	if (!customerId > 0) {
		Swal.fire({
			icon: 'error',
			title: `${t("error")}`,
			text: `${t("mustLogin")}`,
			showCancelButton: false,
			confirmButtonColor: "#3085d6",
			confirmButtonText: "OK"
		}).then((result) => {
			if (result.isConfirmed) {
				window.location.href = '/customer/login'; // Redirect to the home page
			}
		});
	}


	/**
	 * A function to handle removing a product from the cart.
	 *
	 * @param {type} prod - the orderitem ID to be removed
	 * @return {boolean} true if the product was successfully removed
	 */
	const cartRemoveButtonHandler = (prod) => {

		Swal.fire({
			title: `${t("segEliminar")}`,
			text: `${t("noRecuperas")}`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: `${t("siEliminar")}`,
			cancelButtonText: `${t("cancelar")}`
		}).then((result) => {
			if (result.isConfirmed) {
				if (cartData) {
					let cartJson = cartData; // Assuming cartData contains an object with a 'results' property
					cartJson = cartJson.filter((cart) => cart.id !== prod);
					// Update cartData in the context
					setCartData({ ...cartData, results: cartJson });
					// Update cartData in local storage
					axios.delete(constants.SERVER_URL + "orderitems/" + prod, {})
						.then((response) => {
							console.log(response.data);
							window.location.reload();
						})
						.catch((error) => {
							console.error(error);
						});
				}

			}
		});

	}

	async function cambia_vals(val) {
		try {
			const response = await axios.patch(constants.SERVER_URL + "orderlistplus/", val, {});
			const updatedCartData = cartData.results.map((cart) => {
				if (cart !== null && cart.id === val.pk) {
					const precio = cookie.currency === 'usd' ? Number(response.data.price_usd) : Number(response.data.price);
					return {
						"cant": response.data.cant,
						"sku": response.data.sku,
						"attributes": response.data.attributes,
						"price": response.data.price,
						"price_usd": response.data.price_usd,
						"product": {
							"product_id": response.data.product_id,
							"cant": response.data.product.cant,
							"title": response.data.product.title,
							"attributes": response.data.attributes,
							"first_image": response.data.product.first_image
						}
					};
				} else {
					return cart;
				}
			});
			return { ...cartData, results: updatedCartData };
		} catch (error) {
			if (error.response) {
				console.log(error.response.data.msg);
				Swal.fire({
					title: `${t('error')}`,
					text: error.response.data.msg,
					icon: 'error',
					toast: true,
				});
			}
			return cartData; // Return the original cartData if an error occurs
		}
	}

	const cartAddButtonHandler = async (prod) => {
		const updatedCartData = await cambia_vals({ "cant": '1', "pk": prod });
		setCartData(updatedCartData);
	}

	const cartLowerButtonHandler = async (prod) => {
		const updatedCartData = await cambia_vals({ "cant": '-1', "pk": prod });
		setCartData(updatedCartData);
	}


	return (
		<div className="container mt-4">
			<h3 className="mb-4">{t('allItm')} ({cartData && cartData.length})</h3>
			<div className="col-md-12 col-12">
				<div className="table-responsive">
					<table className="table">
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">{t('product')}</th>
								<th scope="col">{t('attr')}</th>
								<th scope="col">{t('price')}</th>
								<th scope="col">{t('qtty')}</th>
								<th scope="col">{t('total')}</th>
								<th scope="col">{t('actions')}</th>
							</tr>
						</thead>
						<tbody>
							{cartData && cartData.length > 0 && cartData.map((item, index) => {
								// const item = cart[0];
								if (cookie.currency === 'eur') {
									precio = item.price;
								} else if (cookie.currency === 'usd') {
									precio = item.price_usd;
								}
								totGen += item.cant * precio
								return (
									<tr key={index}>
										<td className="align-middle" scope="row">{index + 1}</td>
										<td className="align-middle">
											<Link to={`/product/${item.product.title}/${item.product.id}`}>
												<img
													src={constants.SERVER_URL.replace('/api/', '') + item.product.first_image}
													className="img-thumbnail"
													width={50}
													alt={item.product.title}
												/>
											</Link>
											<p>
												<Link to={`/product/${item.product.title}/${item.product.id}`}>{item.product.title}</Link>
											</p>
										</td>
										<td className="align-middle">
											{item.attributes && item.attributes.map((attr, index) => {
												return (
													<div key={index}>
														{attr.title}: {attr.value}
													</div>
												)
											})}
										</td>
										<td className="align-middle">{precio} {moneda}</td>
										<td className="align-middle"><div className="d-flex flex-row align-items-center">
											<div className="me-2">{item.cant}</div>
											<div className="d-flex; flex-column;">
												{item.product.cant > item.cant &&
													<button title={t("addOne")} onClick={() => cartAddButtonHandler(item.id)} type="button" className="btn btn-primary ms-1"><i className="fa-solid fa-plus"></i> </button>
												}
												{item.cant > 1 &&
													<><button title={t("remOne")} onClick={() => cartLowerButtonHandler(item.id)} type="button" className="btn btn-primary ms-1"><i className="fa-solid fa-minus"></i> </button><br /></>
												}
											</div>
										</div>
										</td>
										<td className="align-middle">{(precio * item.cant).toFixed(2)} {moneda}</td>
										<td className="align-middle"><button title={t("remCart")} onClick={() => cartRemoveButtonHandler(item.id)} type="button" className="btn btn-danger ms-1"><i className="fa-solid fa-cart-arrow-down"></i> </button></td>
									</tr>)
							})}
						</tbody>
						<tfoot>
							<tr>
								<th scope="row">{t("total")}</th>
								<th colSpan={3}></th>
								<th>{totGen.toFixed(2)}</th>
							</tr>
							<tr>
								<td align="right" colSpan={5}>
									<Link to={"/"} className="btn btn-secondary" title={t("conCompra")}><i className="fa-solid fa-gift"></i></Link>
									{cartData && cartData.length !== 0 &&
										<Link to={"/confirm-order"} title={t("procPay")} className="btn btn-success ms-1"><i alt={t("procPay")} className="fa-solid fa-cart-shopping"></i></Link>
									}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
	);
}
