import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { SingleProduct } from "./single_product";
import { useState, useEffect } from "react";
import * as constants from "../constants";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";


export function CategoryProducts() {
	const [cookie, setCookie] = useCookies(["currency"]);
	const [products, setProducts] = useState([]);
	const [totalResult, setTotalResults] = useState(0);
	const baseurl = constants.SERVER_URL + "products/";
	const pageSize = cookie.tamPagina;
	const { category_id, category_slug } = useParams();
	// const { t, i18n } = useTranslation(["welcome"]);
	const { t } = useTranslation(["welcome"]);

	useEffect(() => {
		fetchData(baseurl + 'category/' + category_id + "?offset=0&limit=" + pageSize);
	}, [baseurl, category_id, pageSize]);

	function fetchData(url) {
		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				setProducts(data.results);
				setTotalResults(data.count);
			});
	}

	function changeUrl(i) {
		fetchData(baseurl + 'category/' + category_id + "?offset=" + ((i - 1) * pageSize) + "&limit=" + pageSize);
	}

	if (totalResult > pageSize) {
		var links = [];
		var ultmPag = Math.ceil(totalResult / pageSize)
		for (let i = 1; i <= ultmPag; i++) {
			links.push(
				<li key={i} className="page-item">
					<Link className="page-link" onClick={() => changeUrl(i)} to={`/category/${category_slug}/${category_id}/?page=${i}`}>
						{i}
					</Link>
				</li>
			);
		}
	}

	return (
		<section className="container mt-4">
			<h3 className="mb-4">{category_slug} {t("product")}s</h3>
			<div className="row mb-4">
				{/* Product box */}
				{products.map((product) => (
					<SingleProduct product={product} />
				))}
				{/* Product box end */}
			</div>
			{/* Pagination */}
			<nav aria-label="Page navigation example">
				<ul className="pagination">{links}</ul>
			</nav>

			{/* Pagination End*/}
		</section>
	);
}
