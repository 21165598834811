import { Link } from "react-router-dom";
import { SingleProduct } from "./single_product";
import { useState, useEffect } from "react";
import * as constants from "../constants";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

/**
 * Component for displaying all products and pagination
 */
export function AllProducts() {
    const [cookie, setCookie] = useCookies(["currency"]);
    const [products, setProducts] = useState([]); // State for storing products
    const [totalResult, setTotalResults] = useState(0); // State for storing total results
    const baseurl = constants.SERVER_URL + "product/"; // Base URL for fetching products
    const pageSize = cookie.tamPagina; // Number of items per page
    const [orden, setOrden] = useState('');
    // const { t, i18n } = useTranslation(["welcome"]);
    const { t } = useTranslation(["welcome"]);

    /**
     * Fetches data from the specified URL and updates the state
     * @param {string} url - The URL to fetch data from
     */
    function fetchData(url) {
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setProducts(data.results);
                setTotalResults(data.count);
            });
    }

    useEffect(() => {
        fetchData(baseurl + "?order=" + orden + "&offset=0&limit=" + pageSize);
    }, [baseurl, pageSize, orden]);

    /**
     * Changes the URL based on the page number and fetches new data
     * @param {number} i - The page number
     */
    function changeUrl(i) {
        fetchData(baseurl + "?order=" + orden + "&offset=" + ((i - 1) * pageSize) + "&limit=" + pageSize);
    }

    // Generate pagination links if total results are greater than page size
    let links = [];
    if (totalResult > pageSize) {
        const ultmPag = Math.ceil(totalResult / pageSize);
        for (let i = 1; i <= ultmPag; i++) {
            links.push(
                <li key={i} className="page-item">
                    <Link className="page-link" onClick={() => changeUrl(i)} to={`/all_products/?page=${i}`}>
                        {i}
                    </Link>
                </li>
            );
        }
    }

    function ordena(x) {
        setOrden(x);
        fetchData(baseurl + "?order=" + x + "&offset=0&limit=" + pageSize);
    }

    // Render the products and pagination
    return (
        <section className="container mt-4">
            <div className="d-md-flex flex-md-row-reverse align-items-center justify-content-between">
                <div className="mb-3 mb-md-0 d-flex text-nowrap align-middle"><span className=" me-2 align-middle">{t("orderpor")}:</span>
                    <select className="mb-4 me-2" id="order" defaultValue={orden} onChange={(e) => ordena(e.target.value)}>
                        <option value="">{t("rectes")}</option>
                        <option value="pa">{t("price")} asc</option>
                        <option value="pd" >{t("price")} desc</option>
                        <option value="na" >{t("nombre")} asc</option>
                        <option value="nd" >{t("nombre")} desc</option>
                        <option value="op" >{t("Ratg")}</option>
                    </select>
                </div>
                <h3 className="mb-4">{t("allProd")}</h3>
            </div>
            <div className="row mb-4">
                {/* Product box */}
                {products.map((product) => (<SingleProduct key={product.id} product={product} />))}
                {/* Product box end */}
            </div>
            {/* Pagination */}
            <nav aria-label="Page navigation example">
                <ul className="pagination">{links}</ul>
            </nav>
            {/* Pagination End*/}
        </section>
    );
}
