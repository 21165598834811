import { UserContext, CartContext } from "./context";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import * as constants from "../constants";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Modal, Button } from 'react-bootstrap';
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Swal from 'sweetalert2/dist/sweetalert2.all';

// import 'bootstrap/dist/css/bootstrap.min.css';


export function ConfirmOrder() {
	const userContext = useContext(UserContext);
	const stripePromise = loadStripe("pk_test_51PH14KHEc1xRdQzj5CEBO0TfIIOmZzfkXedZ8RzGBwXgYNjnd7KkbEHwU9PG1zghJ8mX6q0tkG1mktM5r8SYOm4Z00nhhS2dDU")
	const [order_id, setOrderId] = useState('');
	const [payMethod, setPayMethod] = useState('');
	const [MethodStripe, setMethodStripe] = useState('0');
	const [MethodPaypal, setMethodPaypal] = useState('0');
	const [orderDetail, setOrderDetail] = useState([]);
	const [responseAmount, setResponseAmount] = useState('');
	const [responseCurrency, setResponseCurrency] = useState('');
	const [paymentCurrency, setPaymentCurrency] = useState('EUR');
	const [responseId, setResponseId] = useState('');
	const [responseUser, setResponseUser] = useState('');
	const [userData, setUserData] = useState({});
	const [orderAmount, setOrderAmount] = useState(0);
	const { cartData, setCartData } = useContext(CartContext);
	const [paid, setPaid] = useState(false);
	const [error, setError] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [cookie, setCookie] = useCookies(["currency"]);
	const moneda = cookie.currency.toUpperCase();
	const customerId = cookie.customer_id; // Customer ID from localStorage
	const { t } = useTranslation(["welcome"]);
	const [paseModal, setPaseModal] = useState(false);

	function fetchAjustesData(url) {
		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				data.map((item, index) => {
					// console.log(item.nombre + " = " + item.valor);
					if (item.nombre === "PagoStripe") {
						setMethodStripe(item.valor);
					}
					if (item.nombre === "PagoPaypal") {
						setMethodPaypal(item.valor);
					}
				});
			});
	}


	const handleApprove = (data, actions) => {

		return actions.order.capture().then((order) => {
			setPaid(true);
			updateOrderStatus();

			// vaciar el carrito
			localStorage.setItem("cartData", JSON.stringify([]));
			const Cart = localStorage.getItem("cartData");
			const cartJsonmod = JSON.parse(Cart);
			setCartData(cartJsonmod);

			// console.log("order- " + JSON.stringify(order));

			// Llenado de los datos de la ventana modal
			setResponseAmount(order.purchase_units[0].amount.value);
			setResponseCurrency(moneda);
			setResponseUser(order.purchase_units[0].shipping.name.full_name);
			setResponseId(order.id);

			// Envio de datos para terminar la compra y mandar correo al Cliente
			const formData = new FormData();

			formData.append("order_id", order_id);
			formData.append("ordertext", JSON.stringify(order));
			formData.append("amount", orderAmount.toString());
			formData.append("currency", moneda);
			formData.append("user", customerId);
			formData.append("payMethod", payMethod);
			formData.append("responseId", order.id);
			formData.append("responseUser", order.purchase_units[0].shipping.name.full_name);

			// console.log("formData- ", JSON.stringify(formData, null, 2));
			axios.put(constants.SERVER_URL + "orderfin/" + order_id + "/", formData, {
				headers: { 'Content-Type': 'application/json' },
			})
				.then((response) => {
					// console.log(response.data);
					console.log('');
				})
				.catch((error) => {
					console.error(error);
				});

			setPaseModal(true);
			setShowModal(true);
		});
	};

	// Cuando se oprime el botón Cerrar del modal
	const handleCloseModal = () => {
		setShowModal(false); // Close the modal
	}


	// Para manejar los errores que reporta el pago
	const handleError = (err) => {
		setError(err);
		console.error(err);
	};

	useEffect(() => {
		if (userContext === null || userContext === false) {
			window.location.href = "/customer/login";
		} else {
			addOrderInTable();
		}
		fetchAjustesData(constants.SERVER_URL + "config/")
	}, [userContext]);

	useEffect(() => {
		if (!showModal && paseModal) {
			window.location.href = '/customer/orders/page=1'; // Redirect to the orders page
		}
	}, [showModal]);


	/**
	 * Asynchronously adds an order in the table and fetches user data based on customer_id from localStorage.
	 */
	async function addOrderInTable() {
		try {
			// Retrieve customer_id from localStorage customer/4/check/
			const customer = customerId;
			const cliente = await axios.get(constants.SERVER_URL + "customer/" + customer + "/check/", {
				headers: { 'Content-Type': 'application/json' },
			});

			setUserData(cliente.data);


			// Create form data with customer_id
			const formData = new FormData();
			formData.append("customer_id", customer);

			// Send a POST request to add order and retrieve the order_id
			const response = await axios.post(constants.SERVER_URL + "user-order/", formData, {
				headers: { 'Content-Type': 'application/json' },
			});

			const order_id = response.data.order;

			// If order_id is retrieved, set the order_id and fetch order items
			if (order_id) {
				setOrderId(order_id);
				await orderItems(order_id, cartData, moneda);
			}
		} catch (error) {
			console.error(error);
		}

	}

	/**
	 * Order items and create order items in the backend.
	 * @param {string} order_id - The ID of the order
	 */
	async function orderItems(order_id, cartItems, moneda) {
		try {
			let total = 0;
			// If cart data exists, process each item in the cart
			if (cartItems) {
				await Promise.all(
					cartItems.map(async (cart, index) => {
						// console.log("Cart- " + JSON.stringify(cart));
						// console.log("Price- " + cart[0].product.price);

						// Create form data to send with the request
						const formData = new FormData();
						formData.append("order", order_id);
						formData.append("product", cart.product.id);
						formData.append("cant", cart.cant);
						formData.append("price", cart.price);
						formData.append("sku", cart.sku);

						// Calculate the total order amount
						total += (cart.price * cart.cant);

						// console.log("orderAmount- ", orderAmount);
						// console.log("Price- ", cart.product.price);
						// console.log("Cant- ", cart.product.quantity);
						// console.log("Total- ", total.toFixed(2));

						// Send a POST request to create the order item
						// const response = await axios.post(constants.SERVER_URL + "orderitemset/", formData, {
						// 	headers: { 'Content-Type': 'application/json' },
						// });

						// localStorage.removeItem("cartData");
					})
				);
				// Update the total order amount
				setOrderAmount(total.toFixed(2));
				// console.log(orderAmount);
			}

		} catch (error) {
			console.error(error);
		}
	}

	function updateOrderStatus() {
		// console.log("Order Status- " + order_id);
		const formData = new FormData();
		formData.append("order_id", order_id);
		formData.append("order_status", 2);
		axios.post(constants.SERVER_URL + "orderstatus/", formData, {
			headers: { 'Content-Type': 'application/json' },
		})
			.then((response) => {
				// console.log(response.data);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	function changePaymentMethod(method) {
		setPayMethod(method);
	}

	const PayNowButton = async function (stripe_id) {
		if (payMethod === 'paypal') {
			window.location.href = constants.SERVER_URL + 'paypal/' + stripe_id;
		} else if (payMethod === 'stripe') {
			// console.log("Order ID- " + stripe_id);
			const formData = new FormData();
			formData.append("amount", orderAmount * 100); // Stripe expects amounts in cents
			formData.append("payment_method_id", stripe_id);
			formData.append("description", "Order ID: " + order_id);
			formData.append("currency", moneda);

			try {
				const response = await axios.post(constants.SERVER_URL + "stripe/", formData, {
					headers: { 'Content-Type': 'application/json' },
				});

				console.log(response);

				if (response && response.data && response.data.client_secret) {
					// console.log(response.data.client_secret);

					updateOrderStatus();
					const message = response.data.client_secret;

					const formData = new FormData();
					formData.append("order_id", order_id);
					formData.append("ordertext", JSON.stringify(response.data));
					formData.append("amount", orderAmount.toString());
					formData.append("currency", moneda);
					formData.append("user", customerId);
					formData.append("payMethod", payMethod);
					formData.append("responseId", message);

					setResponseId(message.substring(0, message.indexOf('_secret')));

					await axios.put(constants.SERVER_URL + "orderfin/" + order_id + "/", formData, {
						headers: { 'Content-Type': 'application/json' },
					});

					setPaseModal(true);
					setShowModal(true);
				} else {
					console.error("Response data is missing client_secret.");
				}
			} catch (error) {
				// console.log(error.response.data.error);
				// console.log(error);
				Swal.fire({
					title: `${t("Payerror")}`,
					text: `${t("striperror")}` + error.response.data.error + `${t("striperror2")}`,
					icon: 'error',
					toast: true,
					showConfirmButton: true,
					// position: 'center',
				});
			}
		} else {
			changePaymentMethod(payMethod);
		}
	}

	const CheckoutForm = () => {
		const stripe = useStripe();
		const elements = useElements();

		const handleStripeSubmit = async (event) => {
			event.preventDefault();
			document.getElementById("payStripe").disabled = true; // Disable payStripe
			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardElement),
			});

			if (!error) {
				// console.log(paymentMethod);
				// console.log(paymentMethod.id);
				await PayNowButton(paymentMethod.id);
				// handleApprove(event, paymentMethod);
			}

		}

		return (
			<form onSubmit={handleStripeSubmit} className="card card-body">
				<h3>Amount to pay: ${(orderAmount)}</h3>
				<CardElement className="form-control" />
				<button id="payStripe" type="submit" className="btn btn-success mt-3"> {t("payNow")} </button>
			</form>
		)
	}

	return (
		<div className="container">
			<div className="row mt-5">
				<div className="col-6 offset-3">
					<div className="card text-center py-3">
						<h3><i className="fa fa-check-circle text-success me-2"></i>{t("ordConf")}</h3>
						<h5>{t("ordId")}: {order_id}</h5>
					</div>
					<div className="card py-3 mt-4 p-3">
						<form>
							<h4 className="mt-4">{t("payMet")}</h4>
							{MethodPaypal === '1' &&
								<div className="form-group">
									<label>
										<input onChange={() => changePaymentMethod('paypal')} type="radio" name="payMethod" /> {t("payPal")}
									</label>
								</div>
							}
							{MethodStripe === '1' &&
								<div className="form-group my-2">
									<label>
										<input onChange={() => changePaymentMethod('stripe')} type="radio" name="payMethod" /> {t("stripe")}
									</label>
								</div>
							}
							{/* <button onClick={PayNowButton} type="button" className="btn btn-success btn-sm mt-3"> Next </button> */}
						</form>
						{payMethod === 'paypal' &&
							<PayPalScriptProvider
								options={{
									'client-id': 'AVdl_ZETS3rbudOG0I_WR8bW8zZ_y7xkFItKyjurRKyePyjsZ1Ftk9GcfgBQ8MYpZD_6hXBHgpaaDXaW',
									currency: moneda,
								}}
							>
								<div>
									<PayPalButtons className="mt-4"
										createOrder={(data, actions) => {
											return actions.order.create({
												intent: "CAPTURE", purchase_units: [
													{
														reference_id: order_id,
														amount: {
															value: orderAmount.toString(),
														},
													},
												],
											});
										}}
										onApprove={handleApprove}
										onError={handleError}
									/>
									{/* {paid && <p>Payment successful! 🎉</p>}
									{error && <p>Error processing payment 😟</p>} */}
								</div>
							</PayPalScriptProvider>
						}
						{payMethod === 'stripe' &&
							<Elements stripe={stripePromise}>
								<div className="container">
									<div className="row">
										<div className="col-6 offset-3">
											<CheckoutForm />
										</div>
									</div>
								</div>
							</Elements>
						}
					</div>
				</div>
			</div>
			{/* Modal */}
			<Modal className="modal" show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t("payData")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!userData.user_last_name && !userData.user_first_name && <h4>{t("dear")}: {userData.user_username_read}</h4>}
					{userData.user_last_name && userData.user_first_name && <h4>{t("dear")}: {userData.user_last_name}, {userData.user_first_name} </h4>}
					<br />
					<h5 className="fw-bold"> {t("yPsyScc")}</h5><br />
					<span className="fw-bold">{t("payDet")}:</span><p>
						{t("payMet")}: {payMethod.substring(0, 1).toUpperCase() + payMethod.substring(1).toLowerCase()}<br />
						{t("bankId")}: {responseId}<br />
						{t("ordId")}: {order_id}<br />
						{t("amount")}: {orderAmount} {moneda}<br />
						{/* {t("paidBy")}: {responseUser} */}</p>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleCloseModal}>
						{t("close")}
					</Button>
				</Modal.Footer>
			</Modal>
		</div >

	)
}

