import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import * as constants from "../../constants";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import Swal from 'sweetalert2/dist/sweetalert2.all';


export function CustomerRegister() {
	// const baseurl = constants.SERVER_URL + 'customer/register/';
	const baseurl = constants.SERVER_URL + 'insert-customer/';
	const [formError, setFormError] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');
	const [registerFormData, setRegisterFormData] = useState({
		"first_name": "",
		"last_name": "",
		"username": "",
		"email": "",
		"mobile": "",
		"pwd": "",
		"rpwd": "",
	});
	const { t } = useTranslation(["welcome"]);
	const [paises, setPaises] = useState([]);
	const [cookie, setCookie] = useCookies(["mrev"]);
	const language = cookie.language;
	axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
	axios.defaults.xsrfCookieName = 'csrftoken';


	// const inputHandler = (event) => {
	// 	setRegisterFormData({
	// 		...registerFormData,
	// 		[event.target.name]: event.target.value
	// 	})
	// };

	const inputHandler = (event) => {
		// 1. Get the entered value and name of the field
		const value = event.target.value;
		const name = event.target.name;

		// 2. Check if the field name is 'mobile' (or your chosen name)
		if (name === 'mobile') {
			// 3. Validate the input using the regular expression
			const isNumber = /^\s*\d+(?:\s*\d+)*\s*$/.test(value);

			// 4. Update state only if the input is valid
			if (isNumber) {
				setRegisterFormData({
					...registerFormData,
					[name]: value
				});
			} else {
				// Optional: Display an error message (see point 3 below)
				console.error('Please enter only numbers for mobile number');
				Swal.fire({
					title: `${t("perfActErr")}`,
					text: `${t("errorMobile")}`,
					icon: 'error',
					toast: true
				});
			}
		} else {
			// 5. Update state for other fields as usual
			setRegisterFormData({
				...registerFormData,
				[name]: value
			});
		}
	};

	useEffect(() => {
		fetch(constants.SERVER_URL + 'pais/')
			.then(response => response.json())
			.then(data => {
				delete data.nombre
				if (language === 'es') { data.nombre = data.nombre_es }
				else if (language === 'en') { data.nombre = data.nombre_en }

				setPaises(data)
			});
	}, []);

	const fetchCSRFToken = async () => {
		try {
			const response = await axios.get(constants.SERVER_URL + 'dame_token/');
			return response.data.csrfToken;
		} catch (error) {
			console.error('Error al obtener el token CSRF:', error);
			return null;
		}
	};


	const submitHandler = async (event) => {
		event.preventDefault(); // Evitar la presentación predeterminada del formulario

		// Construir el cuerpo del formulario codificado en URL
		const formData = new URLSearchParams();
		formData.append('first_name', registerFormData.first_name);
		formData.append('last_name', registerFormData.last_name);
		formData.append('username', registerFormData.username);
		formData.append('email', registerFormData.email);
		formData.append('mobile', registerFormData.mobile.replace(/ /g, ''));
		formData.append('pwd', registerFormData.pwd);
		formData.append('rpwd', registerFormData.rpwd);
		formData.append('pais_id', registerFormData.country);

		try {
			const csrfToken = await fetchCSRFToken();
			if (csrfToken) {
				const response = await axios.post(baseurl, formData, {
					withCredentials: true,
					'X-CSRFTOKEN': csrfToken,
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded', // Establecer el tipo de contenido como formulario codificado en URL
						'Accept-Language': language,
					},
				})
					.then(function (response) {
						if (response.data.bool === false) {
							setFormError(true);
							setErrorMsg(response.data.msg);
						} else {
							setRegisterFormData({
								"first_name": "",
								"last_name": "",
								"username": "",
								"email": "",
								"mobile": "",
								"pwd": "",
								"rpwd": "",
								"country": "0"
							});
							setFormError(false);
							setSuccessMsg(response.data.msg);
						}
					})
					.catch(function (error) {
						Swal.fire({
							icon: 'error',
							title: error.response.data.error,
							text: '',
							showCancelButton: false,
							confirmButtonColor: "#3085d6",
							confirmButtonText: "OK"
						})
					});
			} else {
				console.log('No se pudo obtener el token CSRF.');
			}
		} catch (error) {
			console.log('Error al enviar la solicitud:', error);
		}

	};

	const buttonEnable = registerFormData.username.length > 0 && registerFormData.pwd.length > 0 && registerFormData.rpwd === registerFormData.pwd && registerFormData.email.length > 0 && registerFormData.first_name.length > 0 && registerFormData.last_name.length > 0 && registerFormData.mobile.length > 0 && registerFormData.country > 0;

	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-8 col-12 offset-2">
					<div className="card">
						<h4 className="card-header">{t("regCli")}</h4>
						<div className="card-body">
							<p className="text-muted"><strong>{t("nota")}: </strong>{t("campReq")}</p>
							{formError && <p className="text-danger mt-3">{errorMsg}</p>}
							{successMsg && <p className="text-success mt-3">{successMsg} <Link className="text-success" to="/customer/login">{t("accesoCli")}</Link></p>}
							<form>
								<div className="mb-3">
									<label htmlFor="first_name" className="form-label">
										{t("nombre")}
									</label>
									<input
										type="text" onChange={inputHandler} value={registerFormData.first_name} className="form-control" id="first_name" name="first_name" />
								</div>
								<div className="mb-3">
									<label htmlFor="last_name" className="form-label">
										{t("apellidos")}
									</label>
									<input
										type="text" onChange={inputHandler} value={registerFormData.last_name} className="form-control" id="last_name" name="last_name" />
								</div>
								<div className="mb-3">
									<label htmlFor="username" className="form-label">
										{t("username")}
									</label>
									<input type="text" onChange={inputHandler} value={registerFormData.username} className="form-control" id="username" name="username" />
								</div>
								<div className="mb-3">
									<label htmlFor="email" className="form-label">
										{t("email")}
									</label>
									<input type="email" onChange={inputHandler} value={registerFormData.email} className="form-control" id="email" name="email" />
								</div>
								<div className="mb-3">
									<label htmlFor="mobile" className="form-label">
										{t("telef")}
									</label>
									<div className="input-group">
										<select id="country" defaultValue="" name="country" onChange={inputHandler}>
											<option key={'country0'} value="0">{t("select")}</option>
											{paises && paises.map((pais) => (
												<option key={'country' + pais.id} value={pais.id}>{pais.nombre} (+{pais.codtelf})</option>
											))}
										</select>
										<input type="phone" onChange={inputHandler} value={registerFormData.mobile} className="form-control" id="mobile" name="mobile" />
									</div>
								</div>
								<div className="mb-3">
									<label htmlFor="pwd" className="form-label">
										{t("password")}
									</label>
									<input type="password" onChange={inputHandler} value={registerFormData.pwd} className="form-control" id="pwd" name="pwd" />
								</div>
								<div className="mb-3">
									<label htmlFor="rpwd" className="form-label">
										{t("confPass")}
									</label>
									<input type="password" onChange={inputHandler} value={registerFormData.rpwd} className="form-control" id="rpwd" name="rpwd" />
								</div>

								<button disabled={!buttonEnable} type="button" onClick={submitHandler} className="btn btn-primary">
									{t("enviar")}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
