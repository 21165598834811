import { useState } from "react";
import axios from "../axiosConfig";
import * as constants from "../../constants";
import Swal from 'sweetalert2/dist/sweetalert2.all';
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";


export function CustomerLogin() {
	// const baseurl = constants.SERVER_URL + 'customer/login/';
	const [cookie, setCookie] = useCookies(["ext"]);
	const baseurl = constants.SERVER_URL + 'customer/login/';
	const [formError, setFormError] = useState('');
	const [csrfToken, setCsrfToken] = useState(null);
	let checkCustomer = false;
	const { t } = useTranslation(["welcome"]);
	const [loginFormData, setLoginFormData] = useState({
		"username": "",
		"password": ""
	});

	const inputHandler = (event) => {
		setLoginFormData({
			...loginFormData,
			[event.target.name]: event.target.value
		})
	};

	const getCsrfToken = async () => {
		try {
			const response = await axios.get(constants.SERVER_URL + 'get-csrf-token/');
			// console.log("CSRF Token response:", response.data.csrfToken);
			return response.data.csrfToken;
		} catch (error) {
			console.error("Error fetching CSRF token:", error);
			throw error;
		}
	};

	const submitHandler = async (event) => {
		// enviar datos en formato JSON
		event.preventDefault();
		const csrfToken = await getCsrfToken();
		// console.log("CSRF Token for request:", csrfToken);

		axios.post(constants.SERVER_URL + 'customer/login/', loginFormData, {
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrfToken
			}
		})
			.then(function (response) {

				if (!response.data.msg > 0) {
					setFormError(response.data.msg);
					Swal.fire({
						title: `${t('errorCredenciales')}`,
						text: `${t('tryagain')}`,
						icon: 'error',
						toast: true,
						// position: 'center',
					});
				} else {
					// localStorage.setItem("customer_login", true);
					// localStorage.setItem("customer_id", response.data.msg);
					Cookies.set('customer_id', response.data.msg, { sameSite: 'None', secure: true });
					checkCustomer = true;
					setFormError(false);
					window.location.href = "/customer/dashboard";
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	if (cookie.customer_id > 0) { checkCustomer = true; }
	if (checkCustomer) {
		window.location.href = "/customer/dashboard";
	}
	const buttonEnable = loginFormData.username.length > 0 && loginFormData.password.length > 0;

	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-8 col-12 offset-2">
					<div className="card">
						<h4 className="card-header">{t("accesoCli")}</h4>
						<div className="card-body">
							{formError && <p className="text-danger mt-3">{formError}</p>}
							<form>
								<div className="mb-3">
									<label htmlFor="username" className="form-label">
										{t('username')}
									</label>
									<input type="text" className="form-control" value={loginFormData.username} name="username" id="username" onChange={inputHandler} />
								</div>
								<div className="mb-3">
									<label htmlFor="pwd" className="form-label">
										{t('password')}
									</label>
									<input type="password" className="form-control" value={loginFormData.password} name="password" id="pwd" onChange={inputHandler} />
								</div>
								<button disabled={!buttonEnable} type="button" onClick={submitHandler} className="btn btn-primary">
									{t("enviar")}
								</button><br />
								<div className="container text-center"><Link to="/customer/forget">{t("forgPass")}</Link></div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
