import { Link, useParams } from "react-router-dom";
import { Sidebar } from "./sidebar";
import { useState, useEffect, useContext } from "react";
import * as constants from "../../constants";
import { UserContext, CartContext } from "../context";
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.all';
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "i18next";


export function Orders() {
	const [cookie, setCookie] = useCookies(["mrev"]);
	const customerId = cookie.customer_id; // Customer ID from localStorage
	const baseurl = constants.SERVER_URL + "customer/" + customerId + "/orderlist/"; // Base URL for fetching products
	const pageSize = 10; // Number of orders per page
	const userContext = useContext(UserContext);
	// const [productData, setProducts] = useState([]);
	const cartContext = useContext(CartContext);
	const { cartData, setCartData } = cartContext;
	const [orderItems, setOrderItems] = useState([]); // State for storing orders
	const [totalResult, setTotalResults] = useState(0); // State for storing total results
	let [addPage, setAddPage] = useState(0); // Modifica el paginado al pasar ordenes a otra página
	// const [paso, setPaso] = useState(1); // State for storing current page
	// const [ultOrden, setUltOrden] = useState(''); // State for storing current page props.match.params.pageno
	const { page } = useParams();
	const pageNumber = parseInt(page.replace('page=', '')) || 1;
	const [isLoading, setIsLoading] = useState(true); // Estado para manejar la carga de datos
	const { t } = useTranslation(["welcome"]);
	// const [currency, setCurrency] = useCookies(["currency"]);
	let lang = 'es';
	if (cookie.language) { lang = cookie.language; }


	function addtoCart(data) {
		const productData = data;
		if (userContext !== null && userContext !== false) {
			let previousCart = localStorage.getItem("cartData")
			let cartJson = JSON.parse(previousCart)
			let precio = 0

			if (cookie.currency === 'eur') {
				precio = productData.price;
			} else if (cookie.currency === 'usd') {
				precio = productData.price_usd;
			}

			const cartData = [{
				"product": {
					"product_id": productData.product.id,
					"quantity": productData.cant,
					"qttyAllow": productData.product.cant,
					"title": productData.product.title,
					"attributes": productData.attributes,
					"sku": productData.sku,
					"price": precio,
					"images": constants.SERVER_URL.replace('/api/', '') + productData.product.first_image
				}
			}];
			var cartString = '';
			if (cartJson !== null) {
				cartJson.push(cartData);
				cartString = JSON.stringify(cartJson);
				// localStorage.setItem("cartData", cartString);
				setCartData(cartJson);
			} else {
				var newCartLits = [];
				newCartLits.push(cartData);
				cartString = JSON.stringify(newCartLits);
				// localStorage.setItem("cartData", cartString);
			}

			window.location.href = '/checkout'; // Redirect to the home page

		} else {
			alert("Login to add product to cart");
		}
	}


	function handlePayment(order_id) {
		axios.get(constants.SERVER_URL + "customer/" + customerId + "/orderlist/" + order_id + "/").then((response) => {
			// console.log(response.data);
			response.data.forEach(element => {
				addtoCart(element);
			})

		})
	}


	function deleteOrder(order_id) {
		Swal.fire({
			title: `${t("segEliminar")}`,
			text: `${t("noRecuperas")}`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: `${t("siEliminar")}`,
			cancelButtonText: `${t("cancelar")}`
		}).then((result) => {
			if (result.isConfirmed) {
				axios.get(constants.SERVER_URL + "customer/" + customerId + "/orderlist/" + order_id + "/").then((response) => {
					console.log(response.data);
					response.data.results.forEach(element => {
						axios.delete(constants.SERVER_URL + "orderitems/" + element.id, {})
							.then((response) => {
								console.log(response.data);
							})
							.catch((error) => {
								console.error(error);
							});
					});

					axios.delete(constants.SERVER_URL + "orders/" + response.data.results[0].order.id + "/").then((response) => {
						console.log(response.data);
						fetchData(baseurl + "?offset=0&limit=" + pageSize);
					})
						.catch((error) => {
							console.error(error);
						});

				});
			}
		});
	}


	/**
	 * Fetches data from the specified URL and updates the state
	 * @param {string} url - The URL to fetch data from
	 */
	async function fetchData(url) {
		if (customerId > 0) {
			setIsLoading(true); // Inicia la carga
			await fetch(url)
				.then((response) => response.json())
				.then((data) => {
					setTotalResults(data.count);
					setIsLoading(false); // Finaliza la carga

					let valores = [];

					const ser = data.results.map(itemOrder => {
						let orderid = itemOrder.id;

						const newData = itemOrder.orderitem_set.map(item => {
							// item.unshift({
							// 	"order_id": orderid
							// })
							delete item.product.title;

							if (lang === 'es') {
								item.product.title = item.product.title_es;
							} else {
								item.product.title = item.product.title_en;
							}

							item.attributes.map(attr => {

								delete attr.title;
								delete attr.value;
								if (lang === 'es') {
									attr.title = attr.title_es
									attr.value = attr.value_es
								} else {
									attr.title = attr.title_en
									attr.value = attr.value_en
								}
								return attr;
							})

							return item;
						});
						valores.push(newData);
					});
					setOrderItems(valores);



				})
				.catch((error) => {
					console.error("Error fetching data: ", error);
					setIsLoading(false); // Asegura finalizar la carga incluso si hay un error
				});
		} else {
			Swal.fire({
				title: `${t("unkUser")}`,
				text: `${t("redirLogin")}`,
				icon: "error",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "OK"
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = '/customer/login'; // Redirect to the home page
				}
			});
		}
	}

	useEffect(() => {
		fetchData(baseurl + "?offset=0&limit=" + pageSize);

	}, [baseurl]);


	const calculatePrice = (data, currency) => {
		if (currency === 'eur') {
			return data.price_eur;
		} else if (currency === 'usd') {
			return data.price_usd;
		}
		throw new Error('Moneda no válida');
	};

	const createNewOrderItemData = (productId, currency, sku, price, customerId) => {
		return {
			product_id: productId,
			cant: 1,
			currency,
			sku,
			price,
			customer_id: customerId,
		};
	};


	const cartAddButtonHandler = async (productId) => {
		if (!userContext || userContext === 'false') {
			return;
		}

		try {
			const response = await fetch(`${constants.SERVER_URL}product/${productId}`);
			const data = await response.json();

			const price = calculatePrice(data, cookie.currency);
			const newOrderItemData = createNewOrderItemData(productId, cookie.currency, data.sku, price, customerId);

			const postResponse = await axios.post(`${constants.SERVER_URL}orderitems/`, newOrderItemData, {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (productId === 1) {
				window.location.href = '/checkout';
			} else {
				window.location.reload(true);
			}
		} catch (error) {
			Swal.fire({
				title: `${t('error')}`,
				text: `${t('prodInCart')}`,
				icon: 'error',
				toast: true,
				showConfirmButton: false,
			});
		}
	};


	/**
	 * Changes the URL based on the page number and fetches new data
	 * @param {number} i - The page number
	 */
	function changeUrl(i) {
		fetchData(baseurl + "?offset=" + (((i - 1) * pageSize) - addPage) + "&limit=" + (pageSize + addPage));
		addPage = 0;
	}
	// Generate pagination links if total results are greater than page size
	let links = [];
	if (totalResult > pageSize) {
		const ultmPag = Math.ceil(totalResult / pageSize);
		const numLinks = 6 // Nunúmero de enlaces de páginas
		const mitad = Math.ceil(numLinks / 2);
		let j = 0;
		(mitad - pageNumber >= 0) ? j = pageNumber - 1 : j = mitad;
		let conta = 0;
		for (let i = pageNumber - j; i <= ultmPag; i++) {
			if (i > 0) {
				if (conta <= numLinks) {
					links.push(
						<li key={`page-${i}`} className="page-item">
							<Link className="page-link" onClick={() => { changeUrl(i); }} to={`/customer/orders/page=${i}`}>
								{i}
							</Link>
						</li>
					);
					conta++;
				}
			}
		}
	}


	let ordenes = [];
	// let j = 1;
	console.log(orderItems);
	if (orderItems && orderItems.length > 0 && orderItems[0]) {

		for (let i = 0; i < orderItems.length; i++) {
			let suma = 0;
			let elementos = [];
			let currentOrderId = orderItems[i][0].order.id;
			console.log(i);
			console.log(orderItems[i]);
			console.log(orderItems[i][0]);
			console.log(orderItems[i][0].order);
			console.log(orderItems[i][0].order.id);
			// let orderCount = orderItems[i].order_item_count;
			let orderCount = orderItems[i].length;

			for (let j = 0; j < orderCount; j++) {
				let itemIndex = i;
				// let itemIndex = i + j;

				if (itemIndex < orderItems.length && orderItems[itemIndex][j].order.id === currentOrderId) {
					let orderItem = orderItems[itemIndex];
					// let orderItem = orderItems[j];
					suma += orderItem[j].cant * orderItem[j].price;

					let elemento = (
						<div key={orderItem[j].product.id + itemIndex + 'element'} className={`container`}>
							<div key={orderItem[j].product.id + "row"} className='row'>
								<div key={orderItem[j].product.id + "image"} className="col-4">
									<Link key={orderItem[j].product.id} to={`/product/${orderItem[j].product.slug}/${orderItem[j].product.id}`}>
										<img src={constants.SERVER_URL.replace("api/", "") + orderItem[j].product.first_image}
											className="img-thumbnail"
											width={50}
											alt={orderItem[j].product.title}
										/>
										<p>{orderItem[j].product.title}</p>
									</Link>
								</div>
								<div className="col-3 text-center d-flex align-items-center justify-content-center">
									{orderItem[j].attributes.map((attr, index) => {
										return (
											<>
												{attr.title}: {attr.value}<br />
											</>
										);
									})}
								</div>
								{orderItem[j].currency === "eur" && <div className="col-2 text-center d-flex align-items-center justify-content-center">{orderItem[j].price} &euro;</div>}
								{orderItem[j].currency === "usd" && <div className="col-2 text-center d-flex align-items-center justify-content-center">{orderItem[j].price_usd} $</div>}
								<div className="col-2 text-center d-flex align-items-center justify-content-center">{orderItem[j].cant}</div>
								{orderItem[j].order.estado !== 1 && cookie.mrew === 1 &&
									<div className="col-1 text-center d-flex align-items-center justify-content-center">
										<Link alt={t("addRev")} title={t("addRev")} className="btn btn-success btn-sm" to={`/customer/add_review/${orderItem[j].product.id}`}>
											<i className="fa-solid fa-file-pen"></i>
										</Link>
										<button title={t("compradenuevo")} onClick={() => cartAddButtonHandler(orderItem[j].product.id)} type="button" className="btn btn-primary ms-1"><i className="fa-solid fa-cart-plus"></i></button>
									</div>
								}
							</div>
						</div>
					);
					elementos.push(elemento);
				}
			}
			// i += (orderCount - 1); // Ajustar 'i' para evitar procesar los mismos elementos en el próximo ciclo

			if (elementos.length === 0) {
				addPage = addPage + 1;
				break;
			} else {
				addPage = 0;
			}
			let linea = (
				<tr key={orderItems[i][0].order.id + 'item'}>
					<td className="align-middle text-center">{orderItems[i][0].order.id}</td>
					<td colSpan={3}>{elementos}</td>
					<td className="align-middle text-center">{suma.toFixed(2)}</td>
					<td className="text-center align-middle">
						{orderItems[i][0].order.estado === 1 &&
							<>
								<span className="text-success">
									<i className="fa fa-check-circle"></i> {t("confirmado")}
								</span>
								<br /><br /><button className="btn btn-success btn-sm" onClick={() => handlePayment(orderItems[i][0].order.id)}>{t("toPaid")}</button>
								<br /><br /><button className="btn btn-warning btn-sm" onClick={() => deleteOrder(orderItems[i][0].order.id)}>{t("toDelete")}</button>
							</>
						}
						{orderItems[i][0].order.estado === 2 &&
							<span className="text-success">
								<i className="fa-solid fa-sack-dollar"></i> {t("paid")}
							</span>
						}
						{orderItems[i][0].order.estado === 3 &&
							<span className="text-success">
								<i className="fa fa-times-spinner"></i> {t("processing")}
							</span>
						}
						{orderItems[i][0].order.estado === 4 &&
							<span className="text-success">
								<i className="fa-solid fa-share-from-square"></i> {t("sent")}
							</span>
						}
						{orderItems[i][0].order.estado === 5 &&
							<span className="text-success">
								<i className="fa-solid fa-gift"></i> {t("arrived")}
							</span>
						}
					</td>
				</tr>
			);

			ordenes.push(linea);
		}
	} else {
		let linea = (
			<tr>
				<td colSpan={6} className="text-center">
					{t("noOrders")}
				</td>
			</tr>
		);

		ordenes.push(linea);
	}

	return (
		<div className="container mt-4">
			{isLoading ? (
				<div>Cargando...</div> // Puedes reemplazar esto con un componente de carga más elaborado
			) : (
				<div className="row">
					<div className="col-md-3 col-12 mb-2">
						<Sidebar />
					</div>
					<div className="col-md-9 col-12 mb-2">
						<div className="row">
							<div className="table-responsive">
								<table className="table table-bordered table-striped">
									<thead>
										<tr>
											<th scope="col" className="text-center">{t("order")} #</th>
											<th scope="col" colSpan={3}>
												<div className="container">
													<div className="row">
														<div className="col-4 text-center">
															{t("product")}
														</div>
														<div className="col-3 text-center">
															{t("attr")}
														</div>
														<div className="col-2 text-center">
															{t("price")}
														</div>
														<div className="col-2 text-center">
															{t("qtty")}
														</div>
													</div>
												</div>
											</th>
											<th scope="col" className="text-center">{t("total")}</th>
											<th scope="col" className="text-center">{t("status")}</th>
											{/* <th scope="col">Action</th> */}
										</tr>
									</thead>
									<tbody>
										{ordenes}
									</tbody>
									<tfoot>
										<tr>
											<td align="right" colSpan={6}>
												<Link to={"/"} className="btn btn-secondary">
													{t("conCompra")}
												</Link>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</div>
				</div>
			)}
			{/* Pagination */}
			<nav aria-label="Page navigation example">
				<ul className="pagination">{links}</ul>
			</nav>
			{/* Pagination End*/}
		</div>
	);
}
