
import { SellerSidebar } from "./sidebar";

export function AddProduct(props) {
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-3 col-12 mb-2">
          <SellerSidebar />
        </div>
        <div className="col-md-8 col-12 mb-2">
          <div className="card">
            <h4 className="card-header">Add Product</h4>
            <div className="card-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="Title" className="form-label">
                    Title
                  </label>
                  <input type="text" className="form-control" id="Title" />
                </div>
                <div className="mb-3">
                  <label htmlFor="Price" className="form-label">
                    Price
                  </label>
                  <input type="money" className="form-control" id="Price" />
                </div>
                <div className="mb-3">
                  <label htmlFor="Category" className="form-label">
                    Category
                  </label>
                  <select className="form-control" id="Category">
                    <option selected>Choose...</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="Description" className="form-label">
                    Description
                  </label>
                  <textarea className="form-control" id="Description" />
                </div>
                <div className="mb-3">
                  <label htmlFor="image" className="form-label">
                    Product image
                  </label>
                  <input type="file" className="form-control" id="image" />
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
