import { Link } from "react-router-dom";
import { UserContext, CartContext } from "./context";
import { useContext, useState } from "react";
import axios from "axios";
import * as constants from "../constants";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export function SingleProduct(props) {
	const userContext = useContext(UserContext);
	// const cartContext = useContext(CartContext);
	// const { cartData, setCartData } = cartContext;
	const [ProductInWishList, setProductInWishList] = useState(false);
	const [cookie, setCookie] = useCookies(["ext"]);
	const customer_id = cookie.customer_id;
	const { t } = useTranslation(["welcome"]);
	// var pase = true;

	let lang = 'es';
	if (cookie.language) { lang = cookie.language; }

	if (props.product) {
		const imgUrl = props.product.product_images.map(x => x.images);

		// Capturar el nombre según el idioma:
		delete props.product.title;

		if (lang === 'es') {
			props.product.title = props.product.title_es;
		} else {
			props.product.title = props.product.title_en;
		}

		const checkProductInWishList = (product_id) => {
			if (customer_id > 0) {
				const formData = new FormData();
				formData.append("product", product_id);
				formData.append("customer", customer_id);
				axios.post(constants.SERVER_URL + "check_wishlist/", formData, {
					headers: { 'Content-Type': 'application/json' },
				})
					.then((response) => {
						// console.log(response.data.msg);
						(response.data.msg === true) ? setProductInWishList(true) : setProductInWishList(false);
					})
					.catch((error) => {
						console.error(error);
					});
			};
		}
		const WishlistAddButtonHandler = () => {
			if (userContext !== null && userContext !== false) {
				const formData = new FormData();

				formData.append("product", props.product.id);
				formData.append("customer", customer_id);
				axios.post(constants.SERVER_URL + "wishlist/" + customer_id + "/", formData, {
					headers: { 'Content-Type': 'application/json' },
				})
					.then((response) => {
						console.log(response.data);
						checkProductInWishList(props.product.id);
					})
					.catch((error) => {
						console.error(error);
					});
			} else {
				alert(`${t("mustLogin")}`);
			}

		}

		// checkProductInWishList(props.product.id);

		return (
			<div key={props.product.id} className="col-12 col-md-3 mb-4">
				<div className="card">
					<Link to={`/product/${props.product.slug}/${props.product.id}`}>
						<img
							src={imgUrl[0]}
							className="card-img-top"
							alt={props.product.title} />
					</Link>
					<div className="card-body">
						<h5 className="card-title">
							<Link to={`/product/${props.product.slug}/${props.product.id}`}>{props.product.title}</Link>
						</h5>
						{
							cookie.currency === "eur" && <h5 className="card-title">{t("price")}: &euro; {props.product.price_eur}</h5>
						}
						{
							cookie.currency === "usd" && <h5 className="card-title"> {t("price")} : $ {props.product.price_usd}</h5>
						}

					</div>
				</div>
			</div>
		);
	}
}
