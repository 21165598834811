import { Link } from "react-router-dom";
import { SellerSidebar } from "./sidebar";

export function SellerOrders() {
	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-3 col-12 mb-2">
					<SellerSidebar />
				</div>
				<div className="col-md-9 col-12 mb-2">
					<div className="row">
						<div className="table-responsive">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th scope="col">#</th>
										<th scope="col">Product</th>
										<th scope="col">Price</th>
										<th scope="col">Quantity</th>
										<th scope="col">Total</th>
										<th scope="col">Status</th>
										<th scope="col">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td>
											<Link to={"/product/python-timer/123"}>
												<img
													src="https://www.vealaweb.com/static/images/articulos/updating_websites.webp"
													className="img-thumbnail"
													width={50}
													alt="..."
												/>
											</Link>
											<p>
												<Link to={"/product/python-timer/123"}>Product 1 </Link>
											</p>
										</td>
										<td>$100</td>
										<td>1</td>
										<td>$100</td>
										<td>
											<span className="text-success">
												<i className="fa fa-check-circle"></i> Completed
											</span>
										</td>
										<td>
											<div className="btn-group" role="group">
												<button
													id="btnGroupDrop1"
													type="button"
													className="btn btn-primary dropdown-toggle"
													data-bs-toggle="dropdown"
													aria-expanded="false"
												>
													Change Status
												</button>
												<ul
													className="dropdown-menu"
													aria-labelledby="btnGroupDrop1"
												>
													<li>
														<a className="dropdown-item" href="https://google.com">
															Approve
														</a>
													</li>
													<li>
														<a className="dropdown-item" href="https://google.com">
															Sent
														</a>
													</li>
													<li>
														<a className="dropdown-item" href="https://google.com">
															Complete
														</a>
													</li>
												</ul>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
