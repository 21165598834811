import { Link, useParams } from "react-router-dom";
import { Sidebar } from "./sidebar";
import { useState, useEffect, useContext } from "react";
import * as constants from "../../constants";
import { CartContext } from "../context";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2/dist/sweetalert2.all';


export function WhishList() {
	const [cookie, setCookie] = useCookies(["currency"]);
	const customerId = cookie.customer_id; // Customer ID from localStorage
	const baseurl = constants.SERVER_URL + "wishlist/" + customerId + "/"; // Base URL for fetching products
	const pageSize = 10; // Number of orders per page
	// const userContext = useContext(UserContext);
	// const [productData, setProducts] = useState([]);
	const cartContext = useContext(CartContext);
	// const { cartData, setCartData } = cartContext;
	const [orderItems, setOrderItems] = useState([]); // State for storing orders
	const [totalResult, setTotalResults] = useState(0); // State for storing total results
	let [addPage, setAddPage] = useState(0); // Modifica el paginado al pasar ordenes a otra página
	// const [paso, setPaso] = useState(1); // State for storing current page
	// const [ultOrden, setUltOrden] = useState(''); // State for storing current page props.match.params.pageno
	const { page } = useParams();
	const pageNumber = parseInt(page.replace('page=', '')) || 1;
	const [isLoading, setIsLoading] = useState(true); // Estado para manejar la carga de datos
	const { t } = useTranslation(["welcome"]);
	let lang = 'es';
	if (cookie.language) { lang = cookie.language; }


	function handleDelete(orderId) {
		const formData = new FormData();
		formData.append('product', orderId);
		formData.append('customer', customerId);
		// axios.post(constants.SERVER_URL + 'deletewishlist/', formData)
		// 	.then((response) => {console.log(response);})
		// 	.catch((error) => console.error(error));
		Swal.fire({
			title: `${t("segEliminar")}`,
			text: `${t("noRecuperas")}`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: `${t("siEliminar")}`,
			cancelButtonText: `${t("cancelar")}`
		}).then((result) => {
			if (result.isConfirmed) {

				axios.delete(constants.SERVER_URL + "wishlistdelete/" + orderId + "/")
					.then((response) => { console.log(response); fetchData(baseurl + "?offset=0&limit=" + pageSize); })
					.catch((error) => console.error(error));
			}
		});
	}

	function handleAddtoCart(product_id) {
		const formData = new FormData();
		formData.append('product', product_id);
		formData.append('customer', customerId);
		formData.append('currency', cookie.currency);
		// axios.post(constants.SERVER_URL + 'deletewishlist/', formData)
		// 	.then((response) => {console.log(response);})
		// 	.catch((error) => console.error(error));
		axios.post(constants.SERVER_URL + "addtocart/", formData)
			.then((response) => { window.location.reload(true); fetchData(baseurl + "?offset=0&limit=" + pageSize); })
			.catch((error) => console.error(error));
	}

	/**
	 * Fetches data from the specified URL and updates the state
	 * @param {string} url - The URL to fetch data from
	 */
	async function fetchData(url) {
		setIsLoading(true); // Inicia la carga
		await fetch(url)
			.then((response) => response.json())
			.then((data) => {
				setTotalResults(data.count);
				setIsLoading(false); // Finaliza la carga



				const newData = data.data.map(item => {
					delete item.product.title;

					if (lang === 'es') {
						item.product.title = item.product.title_es;
					} else {
						item.product.title = item.product.title_en;
					}

					item.product.attributes.map(attr => {

						delete attr.title;
						delete attr.value;
						if (lang === 'es') {
							attr.title = attr.title_es
							attr.value = attr.value_es
						} else {
							attr.title = attr.title_en
							attr.value = attr.value_en
						}
						return attr;
					})

					return item;
				});


				setOrderItems(newData);

			})
			.catch((error) => {
				console.error("Error fetching data: ", error);
				setIsLoading(false); // Asegura finalizar la carga incluso si hay un error
			});
	}

	useEffect(() => {
		fetchData(baseurl + "?offset=0&limit=" + pageSize);
	}, [baseurl]);

	/**
	 * Changes the URL based on the page number and fetches new data
	 * @param {number} i - The page number
	 */
	function changeUrl(i) {
		fetchData(baseurl + "?offset=" + (((i - 1) * pageSize) - addPage) + "&limit=" + (pageSize + addPage));
		addPage = 0;
	}
	// Generate pagination links if total results are greater than page size
	let links = [];
	if (totalResult > pageSize) {
		const ultmPag = Math.ceil(totalResult / pageSize);
		const numLinks = 6 // Nunúmero de enlaces de páginas
		const mitad = Math.ceil(numLinks / 2);
		let j = 0;
		(mitad - pageNumber >= 0) ? j = pageNumber - 1 : j = mitad;
		let conta = 0;
		for (let i = pageNumber - j; i <= ultmPag; i++) {
			if (i > 0) {
				if (conta <= numLinks) {
					links.push(
						<li key={`page-${i}`} className="page-item">
							<Link className="page-link" onClick={() => { changeUrl(i); }} to={`/customer/orders/page=${i}`}>
								{i}
							</Link>
						</li>
					);
					conta++;
				}
			}
		}
	}


	let ordenes = [];
	if (orderItems && orderItems.length > 0 && orderItems[0]) {

		for (let i = 0; i < orderItems.length; i++) {
			let suma = 0;
			let elementos = [];

			if (i < orderItems.length) {

				let elemento = (
					<div key={i} >
						<div className='row'>
							<div className="col-4 d-flex align-items-center">
								<Link to={`/product/${orderItems[i].product.slug}/${orderItems[i].product.id}`}>
									<img src={constants.SERVER_URL.replace("api/", "") + orderItems[i].product.product_images[0].images}
										className="img-thumbnail"
										width={50}
										alt={orderItems[i].product.title}
									/>
									<p>{orderItems[i].product.title}</p>
								</Link>
							</div>
							<div className="col-4 align-middle d-flex align-items-center">
								{orderItems[i].product.attributes && orderItems[i].product.attributes.map((attr, index) => {
									return (
										<div className="text-center me-1" key={index}>
											{attr.title}: {attr.value}
										</div>
									)
								})}
							</div>
							{
								cookie.currency === "eur" && <div className="col-4 text-center d-flex align-items-center justify-content-center">{orderItems[i].product.price_eur.toFixed(2)} &euro;</div>
							}
							{
								cookie.currency === "usd" && <div className="col-4 text-center d-flex align-items-center justify-content-center">{orderItems[i].product.price_usd.toFixed(2)} $</div>
							}

							{/* <div className="col-3 text-center">{orderItems[i].product.cant}</div> */}
						</div>
					</div>
				);
				elementos.push(
					elemento
				);

			}
			if (elementos.length === 0) {
				addPage = addPage + 1;
				break;
			} else {
				addPage = 0;
			}
			let linea = (
				<tr key={orderItems[i].product.id}>
					<td colSpan={3}>{elementos}</td>
					<td className="text-center align-middle"><div className="mb-2"><button onClick={() => handleDelete(orderItems[i].id)} alt={t("toDelete")} title={t("toDelete")} className="btn btn-warning btn-sm" ><i className="fa-regular fa-trash-can"></i></button></div><div className="mb-2"><button onClick={() => handleAddtoCart(orderItems[i].product.id)} alt={t("tobasket")} title={t("tobasket")} className="btn btn-warning btn-sm" ><i className="fa-solid fa-cart-shopping"></i></button></div></td>
				</tr>
			);

			ordenes.push(linea);
		}
	} else {
		let linea = (
			<tr>
				<td colSpan={6} className="text-center">
					{t("noProduct")}
				</td>
			</tr>
		);

		ordenes.push(linea);
	}

	return (
		<div className="container mt-4">
			{isLoading ? (
				<div>Cargando...</div> // Puedes reemplazar esto con un componente de carga más elaborado
			) : (
				<div className="row">
					<div className="col-md-3 col-12 mb-2">
						<Sidebar />
					</div>
					<div className="col-md-9 col-12 mb-2">
						<div className="row">
							<div className="table-responsive">
								<table className="table table-bordered table-striped">
									<thead>
										<tr>
											<th scope="col" colSpan={3}>
												<div className="container">
													<div className="row">
														<div className="col-4 text-center">
															{t("product")}
														</div>
														<div className="col-4 text-center">
															{t("attr")}
														</div>
														<div className="col-4 text-center">
															{t("price")}
														</div>
														{/* <div className="col-3 text-center">
															Quantity
														</div> */}
													</div>
												</div>
											</th>
											<th scope="col" className="text-center">{t("status")}</th>
											{/* <th scope="col">Action</th> */}
										</tr>
									</thead>
									<tbody>
										{ordenes}
									</tbody>
									<tfoot>
										<tr>
											<td align="right" colSpan={3}>
												<Link to={"/"} className="btn btn-secondary" alt={t("conCompra")} title={t("conCompra")}>
													<i className="fa-solid fa-gift"></i>
												</Link>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</div>
				</div>
			)}
			{/* Pagination */}
			<nav aria-label="Page navigation example">
				<ul className="pagination">{links}</ul>
			</nav>
			{/* Pagination End*/}
		</div>
	);
}
