
import { Sidebar } from "./sidebar";
import { useState } from 'react';
import * as constants from "../../constants";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.all';
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";


export function AddReview() {
	const [cookie, setCookie] = useCookies(["ext"]);
	const customerId = cookie.customer_id; // Customer ID from localStorage
	const model = useParams().model;
	// const [Data, setData] = useState({});
	const [ReviewForm, setReviewForm] = useState({
		'review_text': '',
		'rating': 1,
	});
	const { t } = useTranslation(["welcome"]);

	// useEffect(() => {

	// 	fetch(constants.SERVER_URL + "customers/" + customerId)
	// 		.then((response) => response.json())
	// 		.then((data) => setData(data))
	// }, [])

	const inputHandler = (event) => {
		setReviewForm({
			...ReviewForm,
			[event.target.name]: event.target.value
		})
	}

	const submitHandler = (event) => {
		// event.preventDefault(); // Prevent the default form submission
		// const FormData=new FormData();
		// // enviar datos en formato JSON
		// FormData.append('review_text', ReviewForm.review_text);
		// FormData.append('rating', ReviewForm.rating);
		// FormData.append('customer', customerId);
		// FormData.append('product', model);
		const data = {
			"reviews": ReviewForm.review_text,
			"rating": ReviewForm.rating,
			"customer": customerId,
			"product": model
		}


		axios.post(constants.SERVER_URL + "ratings/", data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(function (response) {
				console.log(response.data);
				if (response.status !== 201) {
					Swal.fire({
						title: `${t("error")}`,
						text: response.statusText,
						icon: 'error',
						toast: true,
						// position: 'center',
					})
				} else {
					Swal.fire({
						title: `${t("exito")}`,
						text: `${t("resEnv")}`,
						icon: 'success',
						toast: true,
						confirmButtonColor: "#3085d6",
						confirmButtonText: "OK"
					}).then((result) => {
						if (result.isConfirmed) {
							window.location.href = '/customer/orders/page=1';
						}
					});

				}

			})
			.catch(function (error) {
				console.log(error.response.data.msg);
				Swal.fire({
					title: `${t("error")}`,
					text: error.response.data.msg,
					icon: 'error',
					toast: true,
					// position: 'center',
				})
			});
	}

	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-3 col-12 mb-2">
					<Sidebar />
				</div>
				<div className="col-md-8 col-12 mb-2">
					<div className="card">
						<h4 className="card-header">{t("addRev")}</h4>
						<div className="card-body">
							<div className="mb-3">
								<label htmlFor="opwd" className="form-label">
									{t("Revw")}:
								</label>
								<textarea type="text" className="form-control" id="review_text" name="review_text" onChange={inputHandler} />
							</div>
							<div className="mb-3">
								<label htmlFor="pwd" className="form-label">
									{t("Ratg")}:
								</label>
								<select type="password" className="form-control" id="rating" name="rating" onChange={inputHandler}>
									<option value="1">1 {t("star")}</option>
									<option value="2">2 {t("stars")}</option>
									<option value="3">3 {t("stars")}</option>
									<option value="4">4 {t("stars")}</option>
									<option value="5" selected>5 {t("stars")}</option>
								</select>
							</div>
							<button type="button" onClick={submitHandler} className="btn btn-primary">
								{t("enviar")}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
