import { Link } from "react-router-dom";

export function SellerSidebar(props) {
  return (
    <div className="list-group">
      <Link to={"/seller/dashboard"} className="list-group-item">
        Dashboard
      </Link>
      <Link to={"/seller/products"} className="list-group-item">
        Products
      </Link>
      <Link to={"/seller/orders"} className="list-group-item">
        Orders
      </Link>
      <Link to={"/seller/customers"} className="list-group-item">
        Customers
      </Link>
      <Link to={"/seller/reports"} className="list-group-item">
        Reports
      </Link>
      <Link to={"/seller/profile"} className="list-group-item">
        Profile
      </Link>
      <Link to={"/seller/password"} className="list-group-item">
        Change Password
      </Link>
      <Link to={"/seller/logout"} className="list-group-item text-danger">
        Logout
      </Link>
    </div>
  );
}
