import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Sidebar(props) {
  const { t } = useTranslation(["welcome"]);
  return (
    <div className="list-group">
      <Link to={"/customer/dashboard"} className="list-group-item">
        {t("board")}
      </Link>
      <Link to={"/customer/orders/page=1"} className="list-group-item">
        {t("ordenes")}
      </Link>
      <Link to={"/customer/wishlist/page=1"} className="list-group-item">
        {t("lista")}
      </Link>
      <Link to={"/customer/profile"} className="list-group-item">
        {t("perfil")}
      </Link>
      <Link to={"/customer/password"} className="list-group-item">
        {t("cambPass")}
      </Link>
      <Link to={"/customer/addresses"} className="list-group-item">
        {t("direcciones")}
      </Link>
      <Link to={"/customer/logout"} className="list-group-item text-danger">
        {t("salida")}
      </Link>
    </div>
  );
}