import { Routes, Route } from "react-router-dom";
import { Suspense } from "react";

// Assets
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";

// Website
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Home } from "./components/home";
import { Categories } from "./components/categories";
import { AllProducts } from "./components/all_products";
import { SearchProducts } from "./components/search_products";
import { ProductDetail } from "./components/product_detail";
import { CategoryProducts } from "./components/category_products";
import { TagProducts } from "./components/tag_products";
import { Checkout } from "./components/checkout";
import { OrderSuccess } from "./components/order_success";
import { OrderFailure } from "./components/order_failure";
import { ConfirmOrder } from "./components/confirm_order"; 

// Customer
import { CustomerRegister } from "./components/customer/register";
import { CustomerLogin } from "./components/customer/login";
import { CustomerForget } from "./components/customer/forget";
import { CustomerLogout } from "./components/customer/logout";
import { Dashboard } from "./components/customer/dashboard";
import { Orders } from "./components/customer/orders";
import { WhishList } from "./components/customer/wishlist";
import { Profile } from "./components/customer/profile";
import { Password } from "./components/customer/password";
import { AddressList } from "./components/customer/address_list";
import { AddAddress } from "./components/customer/add_address";
import { ModAddress } from "./components/customer/mod_address";
import { AddReview } from "./components/customer/add_review";

// Seller
import { SellerRegister } from "./components/seller/register";
import { SellerLogin } from "./components/seller/login";
import { SellerDashboard } from "./components/seller/dashboard";
import { SellerProducts } from "./components/seller/products";
import { AddProduct } from "./components/seller/add_product";
import { SellerOrders } from "./components/seller/orders";
import { Customers } from "./components/seller/customers";
import { Reports } from "./components/seller/reports";
import { SellerProfile } from "./components/seller/profile";
import { SellerPassword } from "./components/seller/password";

import { CartContext } from "./components/context";
import { useState } from "react";
const checkCart = localStorage.getItem("cartData")

function App() {
	const [cartData, setCartData] = useState(JSON.parse(checkCart));
	return (
		<CartContext.Provider value={{ cartData, setCartData }}>
			<div>
				<div className="fluid">
					{/* Navbar */}
					<Header />
				</div>
				<main className="container mt-4">

					<Routes>
						{/* Website */}
						<Route path="/" element={<Home />} />
						<Route path="/all_products" element={<AllProducts />} />
						<Route path="/search_products" element={<SearchProducts />} />
						<Route path="/categories" element={<Categories />} />
						<Route path="/checkout" element={<Checkout />} />
						<Route path="/product/:tag" element={<TagProducts />} />
						<Route path="/product/:product_slug/:product_id" element={<ProductDetail />} />
						<Route path="/category/:category_slug/:category_id" element={<CategoryProducts />} />
						<Route path="/order/success" element={<OrderSuccess />} />
						<Route path="/order/failure" element={<OrderFailure />} />
						<Route path="/confirm-order" element={<ConfirmOrder />} />

						{/* Customer */}
						<Route path="/customer/register" element={<CustomerRegister />} />
						<Route path="/customer/login" element={<CustomerLogin />} />
						<Route path="/customer/forget" element={<CustomerForget />} />
						<Route path="/customer/logout" element={<CustomerLogout />} />
						<Route path="/customer/dashboard" element={<Dashboard />} />
						<Route path="/customer/orders" exact element={<Orders />} />
						<Route path="/customer/orders/:page" exact element={<Orders />} />
						<Route path="/customer/wishlist/:page" element={<WhishList />} />
						<Route path="/customer/profile" element={<Profile />} />
						<Route path="/customer/password" element={<Password />} />
						<Route path="/customer/addresses" element={<AddressList />} />
						<Route path="/customer/add_address" element={<AddAddress />} />
						<Route path="/customer/mod_address/:address" element={<ModAddress />} />
						<Route path="/customer/add_review/:model" element={<AddReview />} />

						{/* Seller */}
						<Route path="/seller/register" element={<SellerRegister />} />
						<Route path="/seller/login" element={<SellerLogin />} />
						<Route path="/seller/dashboard" element={<SellerDashboard />} />
						<Route path="/seller/products" element={<SellerProducts />} />
						<Route path="/seller/add_product" element={<AddProduct />} />
						<Route path="/seller/orders" element={<SellerOrders />} />
						<Route path="/seller/customers" element={<Customers />} />
						<Route path="/seller/reports" element={<Reports />} />
						<Route path="/seller/profile" element={<SellerProfile />} />
						<Route path="/seller/password" element={<SellerPassword />} />
					</Routes>
				</main>
				<div className="container">
					<Footer />
				</div>
			</div>
		</CartContext.Provider>
	);
}

export default App;
