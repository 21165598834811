
import Cookies from 'js-cookie';

export function CustomerLogout() {
	// localStorage.removeItem("customer_login");
  	// localStorage.removeItem("customer_username");
  	// localStorage.removeItem("customer_id");
	console.log(Cookies.remove('customer_id'));
	if (localStorage.getItem("customer_login")) {
		window.location.href = "/customer/login"
	}
	window.location.href = "/customer/login"
	return (
		<></>
	);
}
