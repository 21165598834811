import { Link } from "react-router-dom";
import { SellerSidebar } from "./sidebar";

export function Customers() {
	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-3 col-12 mb-2">
					<SellerSidebar />
				</div>
				<div className="col-md-9 col-12 mb-2">
					<div className="row">
						<div className="table-responsive">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th scope="col">#</th>
										<th scope="col">Name</th>
										<th scope="col">Email</th>
										<th scope="col">Mobile</th>
										<th scope="col">Action</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1</td>
										<td>
											<p>
												<Link to={"/product/python-timer/123"}>
													Customer 1{" "}
												</Link>
											</p>
										</td>
										<td>sdfg@dsfgsd.fgg</td>
										<td>156735673</td>
										<td>
											<button className="btn btn-sm btn-primary me-1">
												Orders
											</button>
											<button className="btn btn-sm btn-danger">Remove</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
