import { Link } from "react-router-dom";
import { Sidebar } from "./sidebar";
import { useEffect, useState } from "react";
import * as constants from "../../constants";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export function Dashboard(props) {
	const [cookie, setCookie] = useCookies(["ext"]);
	const customerId = cookie.customer_id; // Customer ID from localStorage
	const [DataOrders, setDataOrders] = useState('0');
	const [DataWish, setDataWish] = useState('0');
	const [DataAddress, setDataAddress] = useState('0');
	const [Conta, setConta] = useState(0);
	const [Orden, setOrden] = useState('');
	const { t } = useTranslation(["welcome"]);

	useEffect(() => {
		// fetch(constants.SERVER_URL + "orderslist/" + customerId + "/")
		// 	.then((response) => {
		// 		response.json();
		// 		console.log(response);
		// 	})
		// 	.then((data) => {
		// 		if (data) {
		// 			console.log(data.length);
		// 			setDataOrders(data);
		// 		}
		// 	});

		fetch(constants.SERVER_URL + "orderslist/" + customerId + "/")
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				if (data) {
					console.log(data.length);
					setDataOrders(data.length);
				}
			})
			.catch((error) => console.error('Error fetching orders:', error));

		fetch(constants.SERVER_URL + "wishlist/" + customerId + "/")
			.then((response) => response.json())
			.then((data) => {
				setDataWish(data.length);
			})


		fetch(constants.SERVER_URL + "customer/" + customerId + "/address/")
			.then((response) => response.json())
			.then((data) => {
				setDataAddress(data.length);
			})

	}, []);

	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-3 col-12 mb-2">
					<Sidebar />
				</div>
				<div className="col-md-9 col-12 mb-2">
					<div className="row">
						<div className="col-md-4 mb-2">
							<div className="card">
								<div className="card-body text-center">
									<h4>{t("totOrden")}</h4>
									<h4>
										<Link to={"/customer/orders/1"}>{DataOrders}</Link>
									</h4>
								</div>
							</div>
						</div>
						<div className="col-md-4 mb-2">
							<div className="card">
								<div className="card-body text-center">
									<h4>{t("totWhish")}</h4>
									<h4>
										<Link to={"/customer/wishlist/1"}>{DataWish}</Link>
									</h4>
								</div>
							</div>
						</div>
						<div className="col-md-4 mb-2">
							<div className="card">
								<div className="card-body text-center">
									<h4>{t("totAddre")}</h4>
									<h4>
										<Link to={"/customer/addresses"}>{DataAddress}</Link>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
