
import { Sidebar } from "./sidebar";
import { useEffect, useState } from 'react';
import * as constants from "../../constants";
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.all';
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";


export function Password(props) {
	const [cookie, setCookie] = useCookies(["mrev"]);
	const customerId = cookie.customer_id; // Customer ID from localStorage
	const [Data, setData] = useState({});
	const [PasswordForm, setPasswordForm] = useState({
		'Oldpassword': '',
		'Newpassword': '',
		'Repeatpassword': '',
	});
	const { t } = useTranslation(["welcome"]);

	useEffect(() => {

		fetch(constants.SERVER_URL + "customers/" + customerId)
			.then((response) => response.json())
			.then((data) => setData(data))
	}, [])

	const inputHandler = (event) => {
		setPasswordForm({
			...PasswordForm,
			[event.target.name]: event.target.value
		})
	}

	const submitHandler = (event) => {
		event.preventDefault(); // Prevent the default form submission
		// enviar datos en formato JSON
		let pase = 1;
		let text = '';

		if (PasswordForm.Oldpassword.length < 2) {
			text = `${t("contrActuMust")}`;
			pase = 0;
		}
		/**
				if (PasswordForm.Newpassword.length < 8) {
					text = 'La contraseña debe tener al menos 8 caracteres';
					pase = 0;
				}
		 */
		if (PasswordForm.Newpassword !== PasswordForm.Repeatpassword) {
			pase = 0;
			text = `${t("passNotMatch")}`;
		}

		if (pase === 0) {
			Swal.fire({
				title: `${t("error")}`,
				text: text,
				icon: 'error',
				toast: true,
				// position: 'center',
			})
			return;
		}

		axios.patch(constants.SERVER_URL + "users/" + Data.user_id_read + "/", PasswordForm, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(function (response) {
				console.log(response.data);
				Swal.fire({
					title: `${t("passChang")}`,
					text: `${t("redirLogin")}`,
					icon: "success",
					showCancelButton: false,
					confirmButtonColor: "#3085d6",
					confirmButtonText: "OK"
				}).then((result) => {
					if (result.isConfirmed) {
						localStorage.removeItem("customer_login");
						localStorage.removeItem("customer_username");
						window.location.href = '/customer/login'; // Redirect to the home page
					}
				});
			})
			.catch(function (error) {
				console.log(error.response.data.msg);
				Swal.fire({
					title: `${t("error")}`,
					text: error.response.data.msg,
					icon: 'error',
					toast: true,
					// position: 'center',
				})
			});
	}

	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-md-3 col-12 mb-2">
					<Sidebar />
				</div>
				<div className="col-md-8 col-12 mb-2">
					<div className="card">
						<p className="text-muted mb-2"><strong>{t("nota")}: </strong>{t("campReq")}</p>
						<h4 className="card-header">{t("cambPass")}</h4>
						<div className="card-body">
							<div className="mb-3">
								<label htmlFor="opwd" className="form-label">
									{t("contrActu")}
								</label>
								<input type="password" className="form-control" id="opwd" name="Oldpassword" onChange={inputHandler} />
							</div>
							<div className="mb-3">
								<label htmlFor="pwd" className="form-label">
									{t("newPass")}
								</label>
								<input type="password" className="form-control" id="pwd" name="Newpassword" onChange={inputHandler} />
							</div>
							<div className="mb-3">
								<label htmlFor="rpwd" className="form-label">
									{t("confPass")}
								</label>
								<input type="password" className="form-control" id="rpwd" name="Repeatpassword" onChange={inputHandler} />
							</div>
							<button type="button" onClick={submitHandler} className="btn btn-primary">
								{t("enviar")}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
