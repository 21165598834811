import { Link } from "react-router-dom";
import { SingleProduct } from "./single_product";
import { useState, useEffect } from "react";
import * as constants from "../constants";
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";
import '../video.css'
import YouTubeVideo from "../youtub";
import axios from 'axios';


export function Home() {
	const [cookie, setCookie] = useCookies(["currency"]);
	const [products, setProducts] = useState([]);
	const [popularProducts, setPopularProducts] = useState([]);
	const [popularCategory, setPopularCategory] = useState([]);
	const [welcomeVideo, setWelcomeVideo] = useState('');
	const { t } = useTranslation(["welcome"]);
	const [videoAvailable, setVideoAvailable] = useState(true);
	const [welcomeImage, setWelcomeImage] = useState('');
	let pageSize = 0;

	let lang = 'es';
	if (cookie.language) { lang = cookie.language; }

	if (!cookie.cantini) pageSize = 4;
	else pageSize = cookie.cantini;

	useEffect(() => {
		fetchAjustesData(constants.SERVER_URL + "config/")
		fetchData(constants.SERVER_URL + "product/" + "?offset=0&limit=" + pageSize);
		fetchPopularData(constants.SERVER_URL + "popular_products/" + "?offset=0&limit=" + pageSize);
		fetchPopularCategory(constants.SERVER_URL + "popularcategories/" + "?offset=0&limit=" + pageSize);
	}, [constants.SERVER_URL]);


	/**
	 * Trae los ajustes de la tienda desde la base de datos
	 * 
	 */

	function fetchAjustesData(url) {//Lee y pone en localStorage los ajustes necesarios
		if (!cookie.currency) document.cookie = `currency="eur";`;
		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				data.map((item, index) => {
					// console.log(item.nombre + " = " + item.valor);
					if (item.nombre === "avisoxexistencia") {
						if (!cookie.ext || cookie.ext < item.valor) {
							Cookies.remove('ext');
							Cookies.set('ext', item.valor);
						}
					}
					if (item.nombre === "muestraReviews") {
						if (!cookie.mrew || cookie.mrew < item.valor) {
							Cookies.remove('mrew');
							Cookies.set('mrew', item.valor);
						}
					}
					if (item.nombre === "cantInicio") {
						if (!cookie.cantini || cookie.cantini < item.valor) {
							Cookies.remove('cantini');
							Cookies.set('cantini', item.valor);
						}
					}
					if (item.nombre === "tamPagina") {
						if (!cookie.tamPagina || cookie.tamPagina < item.valor) {
							Cookies.remove('tamPagina');
							Cookies.set('tamPagina', item.valor);
						}
					}
					if (item.nombre === "imagVideo") {
						setWelcomeImage(constants.SERVER_MEDIA + "customer_images/" + item.valor.replaceAll(" ", "_"));
					}
					if (item.nombre === "welcomeVideo") {
						setWelcomeVideo(item.valor);
						checkVideoAvailability(item.valor);
					}
				});
			});
	}

	const checkVideoAvailability = async (videoId) => {
		try {
			// Realizar una solicitud a la API de YouTube Data v3 para obtener detalles del video
			const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=AIzaSyA_SeZ1OUnYOYrSD0xrjwUvNH8_OChFtLE&part=snippet`);

			// Verificar si la respuesta contiene datos del video
			if (response.data.items.length === 0) {
				// El video no está disponible
				setVideoAvailable(false);
			}
		} catch (error) {
			// Manejar cualquier error de la solicitud
			console.error('Error al verificar la disponibilidad del video:', error);
			setVideoAvailable(false);
		}
	};


	/**
	 * Trae los últimos productos insertados
	 *
	 * @param {string} url - The URL from which to fetch the data.
	 */

	function fetchData(url) {
		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				setProducts(data.results);
			});
	}

	/**
	 * Trae los productos +populares
	 * @param {string} url 
	 */
	function fetchPopularData(url) {
		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				setPopularProducts(data.results);
			});
	}

	/**
	 * Trae las categorías +populares
	 * @param {string} url 
	 */
	function fetchPopularCategory(url) {
		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				setPopularCategory(data.results);
			});
	}


	return (
		<div>
			<div className="embed-responsive embed-responsive-16by9 bytPlayerWrap mb-4" style={{
				backgroundImage: `url(${welcomeImage})`
			}}>
				{videoAvailable ? <YouTubeVideo videoId={welcomeVideo} /> : null}

				{/* {videoAvailable ? <YouTubeVideo videoId={welcomeVideo} /> : null} */}
			</div>
			{/* Latest Products */}
			<div className="row">
				<h4 className="mb-4">
					{t("ultProduct")}
					<Link to="/all_products" className="float-end btn btn-dark">
						{t("veAproduct")} <i className="fa-solid fa-arrow-right-long"></i>
					</Link>
				</h4>
			</div>
			<div className="row mb-4">
				{/* Product box */}
				{products.map((product) => (
					<SingleProduct key={product.id} product={product} />
				))}
				{/* Product box end */}
			</div>
			{/* End of Latest Products */}

			{/* Popular Categories */}
			<div className="row">
				<h4 className="mb-4">
					{t("popCateg")}
					<Link to="/categories" className="float-end btn btn-dark">
						{t("allCateg")} <i className="fa-solid fa-arrow-right-long"></i>
					</Link>
				</h4>
			</div>
			<div className="row mb-4">
				{/* Category box */}

				{popularCategory.map((category) => (
					<div key={category.id} className="col-12 col-md-3 mb-4">
						<div className="card">
							<img
								src="https://www.vealaweb.com/static/images/articulos/updating_websites.webp"
								className="card-img-top"
								alt="{category.title}"
							/>
							<div className="card-body">
								<h4 className="card-title">
									<Link to={`/category/${category.title}/${category.id}`}>{category.title}</Link>
								</h4>
								<div className="card-footer">{t("descProd")}</div>
							</div>
						</div>
					</div>
				))}
				{/* Category box end */}
			</div>
			{/* End of Popular Categories */}

			{/* Popular Products */}
			<div className="row">
				<h4 className="mb-4">
					{t("popProducts")}
					<Link to="/all_products" className="float-end btn btn-dark">
						{t("veAproduct")} <i className="fa-solid fa-arrow-right-long"></i>
					</Link>
				</h4>
			</div>
			<div className="row mb-4">
				{/* Product box */}

				{popularProducts.map((product) => (
					<SingleProduct key={product.id} product={product} />
				))}

				{/* Products box end */}
			</div>
			{/* End of Popular Products */}

			{/* Popular Sellers */}
			{/* <h4 className="mb-4">
				Popular Sellers{" "}
				<a href="https://google.com" className="float-end btn btn-dark">
					View All Sellers <i className="fa-solid fa-arrow-right-long"></i>
				</a>
			</h4>
			<div className="row mb-4">
				<div className="col-12 col-md-3 mb-4">
					<div className="card">
						<img
							src="https://www.vealaweb.com/static/images/articulos/updating_websites.webp"
							className="card-img-top"
							alt="..."
						/>
						<div className="card-body">
							<h4 className="card-title">Seller name</h4>
							<div className="card-footer">
								Categories: <a href="https://google.com">Category 1</a>,{" "}
								<a href="https://google.com">Category 2</a>,
							</div>
						</div>
					</div>
				</div>
			</div> */}
			{/* End of Popular Sellers */}

			{/* Ratings and Reviews */}
			<div
				id="carouselExampleIndicators"
				className="carousel slide my-4 border bg-dark text-white p-5"
				data-bs-ride="carousel"
			>
				<div className="carousel-indicators">
					<button
						type="button"
						data-bs-target="#carouselExampleIndicators"
						data-bs-slide-to="0"
						className="active"
						aria-current="true"
						aria-label="Slide 1"
					></button>
					<button
						type="button"
						data-bs-target="#carouselExampleIndicators"
						data-bs-slide-to="1"
						aria-label="Slide 2"
					></button>
					<button
						type="button"
						data-bs-target="#carouselExampleIndicators"
						data-bs-slide-to="2"
						aria-label="Slide 3"
					></button>
				</div>
				<div className="carousel-inner">
					<div className="carousel-item active">
						<figure className="text-center">
							<blockquote className="blockquote">
								<p>A well-known quote, contained in a blockquote element1.</p>
							</blockquote>
							<figcaption className="blockquote-footer">
								<i className="fa fa-star text-warning"></i>
								<cite title="Source Title">Customer Name</cite>
							</figcaption>
						</figure>
					</div>
					<div className="carousel-item">
						<figure className="text-center">
							<blockquote className="blockquote">
								<p>A well-known quote, contained in a blockquote element2.</p>
							</blockquote>
							<figcaption className="blockquote-footer">
								<i className="fa fa-star text-warning"></i>
								<cite title="Source Title">Customer Name</cite>
							</figcaption>
						</figure>
					</div>
					<div className="carousel-item">
						<figure className="text-center">
							<blockquote className="blockquote">
								<p>A well-known quote, contained in a blockquote element3.</p>
							</blockquote>
							<figcaption className="blockquote-footer">
								<i className="fa fa-star text-warning"></i>
								<cite title="Source Title">Customer Name</cite>
							</figcaption>
						</figure>
					</div>
				</div>
				<button
					className="carousel-control-prev"
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide="prev"
				>
					<span
						className="carousel-control-prev-icon"
						aria-hidden="true"
					></span>
					<span className="visually-hidden">Previous</span>
				</button>
				<button
					className="carousel-control-next"
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide="next"
				>
					<span
						className="carousel-control-next-icon"
						aria-hidden="true"
					></span>
					<span className="visually-hidden">Next</span>
				</button>
			</div>
			{/* End of Ratings and Reviews */}
		</div>

	);
}
