import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


export function OrderSuccess() {
  const { t } = useTranslation(["welcome"]);
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8 offset-2">
          <div className="card">
            <div className="card-body text-center">
              <p>
                <i className="fa fa-check-circle text-success fa-3x"></i>
              </p>
              <h3 className="text-success"> {t("graOrd")}</h3>
              <p className="mt-4">
                <Link className="btn btn-primary" to={"/"}>
                  {t("inicio")}
                </Link>
                <Link
                  className="btn btn-secondary ms-2"
                  to={"/customer/dashboard"}
                >
                  {t("board")}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
