import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext, CartContext } from "./context";
import * as constants from "../constants";
import { useCookies } from "react-cookie";
// import Cookies from 'js-cookie';
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';


export function Header() {
	const [cookie, setCookie] = useCookies(["currency"]);
	const userContext = useContext(UserContext);
	const customerId = cookie.customer_id; // Customer ID from localStorage 
	const baseurl = constants.SERVER_URL; // Base URL for fetching products 
	const { cartData, setCartData } = useContext(CartContext);
	const navigate = useNavigate();
	const [SearchForm, setSearchForm] = useState({
		'search': '',
	});
	const [username, setUsername] = useState('');
	const { t } = useTranslation(["welcome"]);


	const changeLanguage = (e) => {
		var _language = e.target.value;
		document.cookie = `language="${_language}";`;
		i18next.changeLanguage(_language);
		window.location.reload();
	}


	useEffect(() => {
		if (!cookie.language) {
			Cookies.remove('language');
			Cookies.set('language', 'es');
			i18next.changeLanguage('es');
		} else {
			i18next.changeLanguage(cookie.language);
		}
		if (!cookie.currency) {
			document.cookie = `currency="eur";`;
		}
		if (customerId > 0) {
			fetch(baseurl + "customer/" + customerId + "/orderlista/")
				.then(response => response.json())
				.then(data => {
					setCartData(data);
				})
				.catch(error => {
					console.error('Error:', error);
				});

			fetch(baseurl + "customers/" + customerId + "/")
				.then((response) => response.json())
				.then((data) => {
					if (data.user_username_read) {
						setUsername(data.user_username_read);
					} else {
						console.warn("Username not found in response data.");
					}
				})
				.catch((error) => {
					console.error("Error fetching user data:", error);
				});
		}
	}, []);


	const inputHandler = (event) => {
		setSearchForm({
			...SearchForm,
			[event.target.name]: event.target.value
		})
	}

	const changeCurrency = (e) => {
		var _currency = e.target.value;
		// localStorage.setItem("currency", _currency);
		document.cookie = `currency=${_currency};`;
		window.location.reload();
	}

	const handleSubmit = (event) => {
		event.preventDefault();

		if (SearchForm.search === '') {
			return;
		}
		navigate('/search_products', {
			state: { data: SearchForm.search },
		});
	};

	return (
		<nav className="navbar navbar-expand-lg navbar-dark bg-success">
			<div className="container">
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<Link className="navbar-brand" to="/">
					<h1>{t("title")}</h1>
				</Link>
				<div className="collapse navbar-collapse" id="navbarTogglerDemo03">
					<ul className="navbar-nav ms-auto mb-2 mb-lg-0 me-3">
						<li className="nav-item">
							<Link className="nav-link" aria-current="page" to="/">
								{t("inicio")}
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/categories">
								{t("categorias")}
							</Link>
						</li>
						{cartData && cartData.length > 0 && (

							<li className="nav-item">
								<Link className="nav-link" to="/checkout"><i className="fas fa-shopping-cart" />
									({cartData.length})
								</Link>
							</li>
						)}
					</ul>
					<form className="d-flex">
						<input
							className="form-control me-2"
							type="search"
							placeholder={t("buscar")}
							aria-label="Search"
							name="search"
							onChange={inputHandler}
						/>
						<button className="btn btn-light" onClick={handleSubmit} type="submit">
							{t("buscar")}
						</button>
					</form>
					<ul className="navbar-nav mb-lg-0 ms-3">
						<li className="nav-item dropdown">
							{(!customerId || customerId < 0) &&
								<>
									<a className="nav-link dropdown-toggle" href="https://google.com" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{t("aclientes")}</a>
									<ul className="dropdown-menu" aria-labelledby="navbarDropdown">

										<li><Link className="dropdown-item" to="/customer/register">{t("registro")}</Link></li>
										<li><Link className="dropdown-item" to="/customer/login">{t("acceso")}</Link></li>

									</ul>
								</>
							}
							{(customerId > 0) &&
								<>
									<a className="nav-link dropdown-toggle" href="https://google.com" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{t("saludo", { nombre: username })}</a>
									<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
										<li><Link className="dropdown-item" to="/customer/dashboard">{t("board")}</Link></li>
										<li><Link className="dropdown-item" to="/customer/logout">{t("salida")}</Link></li>

									</ul>
								</>
							}
						</li>
					</ul>
					<ul className="navbar-nav mb-lg-0 ms-3">
						<li className="nav-item dropdown">
							{/* <a className="nav-link dropdown-toggle" href="https://google.com" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								Vendor Area
							</a> */}
							<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
								<li>
									<Link className="dropdown-item" to="/seller/register">
										{t("registro")}
									</Link>
								</li>
								<li>
									<Link className="dropdown-item" to="/seller/login">{t("acceso")}</Link>
								</li>
								<li>
									<hr className="dropdown-divider" />
								</li>
								<li><Link className="dropdown-item" to="/seller/dashboard">{t("board")}</Link></li>
								<li><Link className="dropdown-item" to="/seller/logout">{t("salida")}</Link></li>
							</ul>
						</li>
					</ul>
					<ul className="navbar-nav mb-lg-0 ms-3">
						<li>
							<select onChange={changeCurrency} defaultValue={cookie.currency || "eur"}>
								<option value={"eur"} >EUR</option>
								<option value={"usd"}>USD</option>
							</select>
						</li>
					</ul>
					<ul className="navbar-nav mb-lg-0 ms-3">
						<li>
							<select onChange={changeLanguage} defaultValue={cookie.language || "es"}>
								<option value={"es"} >ES</option>
								<option value={"en"}>EN</option>
							</select>
						</li>
					</ul>
				</div>
			</div >
		</nav >
	);
}
