import React from 'react';
import ReactPlayer from 'react-player';

const YouTubeVideo = (props) => {
	const handleReady = () => {
		const endScreenElements = document.getElementsByClassName('bytPlayerWrap');
		if (endScreenElements.length > 0) {
			endScreenElements[0].style.display = 'block';
		}
	};

	const handleStateChange = (state) => {
		// Verificar si el estado del video es "finalizado" (estado 0)
		if (state === 'ended') {
			const endScreenElements = document.getElementsByClassName('bytPlayerWrap');
			if (endScreenElements.length > 0) {
				// endScreenElements[0].style.display = 'none';
				endScreenElements[0].innerHTML = '';
			}
		}
	};

	return (
		<ReactPlayer
			url={`https://www.youtube.com/watch?v=${props.videoId}`}
			width='100%'
			height='100%'
			playing
			controls={false}
			config={{
				youtube: {
					playerVars: {
						autoplay: 1,
						controls: 0,
						rel: 0,
						showinfo: 0,
					},
				},
			}}
			onReady={handleReady}
			onEnded={() => handleStateChange('ended')}
		/>
	);
};

export default YouTubeVideo;
